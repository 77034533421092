import React from 'react'
import {
    Route,
    Router,
    Switch
} from 'react-router-dom'
import shortid from 'shortid'
import { createBrowserHistory } from 'history'
import {
    About,
    Affiliate,
    BuyPlan,
    ChangePassword,
    DataDeletionInstructions,
    Favorites,
    ForgotPassword,
    Golomt,
    Home,
    Lessons,
    Login,
    NotFound,
    Post,
    Posts,
    PrivacyPolicy,
    Product,
    Products,
    Profile,
    Questions,
    Register,
    TermsConditions,
    UpdateMe,
    UseCoupon,
    VerifyPhone,
    Video,
    Videos
} from './pages'

const history = createBrowserHistory()

export { history }

export default () => (
    <Router history={history}>
        <Switch>
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/login"
                component={Login}
                exact
            />
            <Route
                path="/register"
                component={Register}
                exact
            />
            <Route
                path="/verify/phone"
                component={VerifyPhone}
                exact
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
                exact
            />
            <Route
                path="/profile"
                component={Profile}
                exact
            />
            <Route
                path="/affiliate"
                component={Affiliate}
                exact
            />
            <Route
                path="/update/profile"
                component={UpdateMe}
                exact
            />
            <Route
                path="/change/password"
                component={ChangePassword}
                exact
            />
            <Route
                path="/use/coupon"
                component={UseCoupon}
                exact
            />
            <Route
                path="/favorites"
                component={Favorites}
                exact
            />
            <Route
                path="/buy/plan"
                component={BuyPlan}
                exact
            />
            <Route
                path="/golomt"
                component={Golomt}
                exact
            />
            <Route
                path="/about/us"
                component={About}
                exact
            />
            <Route
                path="/lessons"
                component={Lessons}
                exact
            />
            <Route
                path="/videos"
                render={props => (
                    <Videos key={shortid.generate()} {...props} />
                )}
                exact
            />
            <Route
                path="/videos/:id"
                render={props => (
                    <Video key={shortid.generate()} {...props} />
                )}
                exact
            />
            <Route
                path="/posts"
                component={Posts}
                exact
            />
            <Route
                path="/posts/:id"
                component={Post}
                exact
            />
            <Route
                path="/privacy/policy"
                component={PrivacyPolicy}
                exact
            />
            <Route
                path="/products"
                component={Products}
                exact
            />
            <Route
                path="/products/:id"
                component={Product}
                exact
            />
            <Route
                path="/questions"
                component={Questions}
                exact
            />
            <Route
                path="/terms/conditions"
                component={TermsConditions}
                exact
            />
            <Route
                path="/facebook-data-deletion-instructions"
                component={DataDeletionInstructions}
                exact
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
