import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Link as RouterLink,
    NavLink
} from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    AppBar,
    Button,
    Container,
    Hidden,
    IconButton,
    Toolbar
} from '@material-ui/core'
import { Menu } from 'mdi-material-ui'
import { ThemeContext } from './../theme/context'
import { UserMenu } from './../components'

const styles = theme => ({
    grow: {
        flexGrow: 1
    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: '#ffc700'
    },
    avatar: {
        margin: theme.spacing(0, 1)
    },
    pattern: {
        height: 80,
        backgroundSize: 'auto 80px',
        backgroundRepeat: 'repeat-x',
        position: 'fixed',
        top: 0,
        left: 'auto',
        right: 0,
        width: '100%',
        zIndex: 1100
    },
    menuButton: {
        marginLeft: -28,
        marginRight: theme.spacing(2)
    },
    button: {
        fontSize: 20,
        textTransform: 'none',
        margin: theme.spacing(0, 1)
    }
})

class MainBar extends Component {
    static contextType = ThemeContext

    render() {
        const { toggleDrawer } = this.context
        const { user } = this.props
        const {
            grow,
            appBar,
            avatar,
            pattern,
            menuButton,
            button
        } = this.props.classes

        const Link = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />)

        let authButton

        if (user) {
            authButton = (
                <UserMenu className={avatar} />
            )
        } else {
            authButton = (
                <Button
                    className={button}
                    component={Link}
                    variant="outlined"
                    color="inherit"
                    to="/login"
                >
                    Нэвтрэх
                </Button>
            )
        }

        return (
            <div>
                <div
                    className={pattern}
                    style={{
                        backgroundImage: `url('/static/images/pattern.png')`
                    }}
                ></div>
                <AppBar className={appBar}>
                    <Container>
                        <Toolbar>
                            <Hidden lgUp>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    className={menuButton}
                                    onClick={toggleDrawer}
                                >
                                    <Menu />
                                </IconButton>
                            </Hidden>
                            <Hidden mdDown>
                                <RouterLink to="/">
                                    <img
                                        alt="logo"
                                        src="/static/images/logo-small.png"
                                        height="35"
                                    />
                                </RouterLink>
                            </Hidden>
                            <div className={grow} />
                            <Hidden lgUp>
                                {authButton}
                            </Hidden>
                            <Hidden mdDown>
                                <Button
                                    className={button}
                                    component={Link}
                                    color="inherit"
                                    to="/about/us"
                                >
                                    Бид
                                </Button>
                                <Button
                                    className={button}
                                    component={Link}
                                    color="inherit"
                                    to="/videos"
                                >
                                    Бүтээл
                                </Button>
                                <Button
                                    className={button}
                                    component={Link}
                                    color="inherit"
                                    to="/products"
                                >
                                    Бүтээгдэхүүн
                                </Button>
                                <Button
                                    className={button}
                                    component={Link}
                                    color="inherit"
                                    to="/posts"
                                >
                                    Зөвлөгөө
                                </Button>
                                <Button
                                    className={button}
                                    component={Link}
                                    color="inherit"
                                    to="/lessons"
                                >
                                    Багш нарт
                                </Button>
                                <Button
                                    className={button}
                                    component={Link}
                                    color="inherit"
                                    to="/questions"
                                >
                                    Түгээмэл асуулт
                                </Button>
                                {authButton}
                            </Hidden>
                        </Toolbar>
                    </Container>
                </AppBar>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(withStyles(styles)(MainBar))
