import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Authenticate } from './../layouts'
import {
    Section,
    CardVideo
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class Favorites extends Component {
    constructor(props) {
        super(props)

        this.state = {
            videos: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { videos } = await axios.get('favorites', { progress: true })
            this.setState({ videos })
        } catch (err) {
            //
        }
    }

    render() {
        return (
            <Authenticate>
                <Section title="Миний сан">
                    <Grid
                        spacing={4}
                        container
                    >
                        {this.state.videos.map(({ _id, name, coverSrc, isFree }) =>
                            <Grid
                                key={_id}
                                xs={12}
                                sm={4}
                                lg={3}
                                item
                            >
                                <CardVideo
                                    id={_id}
                                    name={name}
                                    imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                    isFree={isFree}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

export default withStyles(styles)(Favorites)
