import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Typography,
    withWidth
} from '@material-ui/core'
import random from 'lodash/random'

const effect = {
    transform: 'scale(1.03)'
}

const styles = theme => ({
    card: {
        position: 'relative',
        transition: 'transform 0.3s',
        '&:hover': effect,
        '&:focus': effect
    },
    cardImage: {
        display: 'block',
        position: 'absolute',
        top: '10%',
        bottom: '35%',
        left: '7%',
        right: '7%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1
    },
    cardFrame: {
        display: 'block',
        position: 'relative',
        width: '100%',
        filter: 'drop-shadow(2px 2px 5px #999)',
        zIndex: 2
    },
    title: {
        position: 'absolute',
        width: '70%',
        left: '15%',
        bottom: '21%',
        transform: 'translateY(50%)',
        zIndex: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)',
        color: '#fff'
    }
})

class CardCategory extends Component {
    render() {
        const {
            id,
            name,
            imageSrc,
            width
        } = this.props
        const {
            card,
            cardImage,
            cardFrame,
            title
        } = this.props.classes

        return (
            <div>
                <Link to={`/videos?categories[]=${id}`}>
                    <div className={card}>
                        <img
                            className={cardFrame}
                            src={`/static/images/frame-${random(5)}.png`}
                            alt="frame"
                        />
                        <div
                            className={cardImage}
                            style={{
                                backgroundImage: `url(https://images.weserv.nl/?url=${imageSrc}&w=330&q=100)`
                            }}
                        ></div>
                        <Typography
                            className={title}
                            variant={width === 'xs' ? 'subtitle2' : 'h6'}
                            component="div"
                            noWrap
                        >
                            {name}
                        </Typography>
                    </div>
                </Link>
            </div>
        )
    }
}

export default withStyles(styles)(withWidth()(CardCategory))
