import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Card,
    CardContent,
    Typography
} from '@material-ui/core'
import { BaseButton } from './../components'

const styles = theme => ({
    card: {
        borderRadius: 20,
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    },
    price: {
        fontSize: 36,
        color: '#1f6692'
    },
    priceDivider: {
        margin: theme.spacing(0, 1),
        fontSize: 22,
        wordSpacing: 2
    },
    button: {
        padding: '4px 32px'
    }
})

class CardPost extends Component {
    render() {
        const {
            card,
            price,
            priceDivider,
            button
        } = this.props.classes

        return (
            <div>
                <Card className={card}>
                    <CardContent>
                        <Box mt={2}>
                            <Typography
                                variant="h6"
                                align="center"
                            >
                                Үндсэн багц
                            </Typography>
                        </Box>
                        <Box my={3}>
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="body1"
                            >
                                Бүгдийг интернэтгүй үед ч үзэх боломжтой 6 сарын хязгааргүй хэрэглээ.
                            </Typography>
                        </Box>
                        <Box
                            textAlign="center"
                            my={3}
                        >
                            <span className={price}>15'000</span>
                            <span className={priceDivider}>төг / 6 сар</span>
                        </Box>
                        <Box
                            textAlign="center"
                            mt={1}
                        >
                            <BaseButton
                                className={button}
                                component={Link}
                                to="/buy/plan"
                            >
                                Худалдаж авах
                            </BaseButton>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(CardPost)
