import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Checkbox } from '@material-ui/core'

const styles = theme => ({
    //
})

class CheckBoxes extends Component {
    add = value => {
        const items = this.props.value.concat(value)
        this.props.onChange(items)
    }

    remove = value => {
        const items = this.props.value.filter(item => item !== value)
        this.props.onChange(items)
    }

    toggle = value => (e, checked) => {
        checked
            ? this.add(value)
            : this.remove(value)
    }

    render() {
        const {
            children,
            value: checkedValues
        } = this.props

        const createCheckBox = ({ value }) => (
            <Checkbox
                checked={checkedValues.includes(value)}
                onChange={this.toggle(value)}
                value={value}
            />
        )

        return (
            <div>
                {children(createCheckBox)}
            </div>
        )
    }
}

export default withStyles(styles)(CheckBoxes)
