import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { App } from './../layouts'
import {
    Section,
    CardProduct
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class Products extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: []
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('products', {
                progress: true,
                params: {
                    sortBy: 'title',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                products: data
            })
        } catch (err) {
            //
        }
    }

    render() {
        return (
            <App>
                <Section title="Бүтээгдэхүүн">
                    <Grid
                        spacing={4}
                        container
                    >
                        {this.state.products.map(({ _id, title, coverSrc }) =>
                            <Grid
                                key={_id}
                                xs={12}
                                sm={4}
                                item
                            >
                                <CardProduct
                                    id={_id}
                                    title={title}
                                    imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(Products)
