import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { theme } from './theme/muiTheme'
import Routes from './routes'

export default () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet
            defaultTitle="Марчаахай"
            titleTemplate="%s - Марчаахай"
        >
            <meta property="og:title" content="Марчаахай" />
            <meta property="og:description" content="Та хүүхдийн 150 гаруй танин мэдэхүйн уран бүтээлийг хязгааргүй үзэх боломжтой." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://marchaakhai.mn" />
            <meta property="og:image" content="https://marchaakhai.mn/static/images/og.jpg" />
        </Helmet>
        <MessengerCustomerChat
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
            loggedInGreeting="Сайн байна уу, Та лавлах зүйлсээ бичнэ үү!"
            loggedOutGreeting="Сайн байна уу, Та лавлах зүйлсээ бичнэ үү!"
        />
        <Routes />
    </ThemeProvider>
)
