import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Slider from 'react-slick'
import axios from './../plugins/axios'

const styles = theme => ({
    slideItem: {
        display: 'block',
        height: 'auto',
        paddingTop: '56.25%',
        maxHeight: 700,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('lg')]: {
            paddingTop: 0,
            height: 'calc(100vh - 64px)',
        }
    }
})

class HomeCarousel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            slides: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('slides', {
                progress: true
            })

            this.setState({
                slides: data
            })
        } catch (err) {
            //
        }
    }

    render() {
        const { slides } = this.state
        const { slideItem } = this.props.classes

        const settings = {
            dots: true,
            arrows: false,
            autoplay: true,
            swipeToSlide: true
        }

        return (
            <Slider {...settings}>
                {slides.map(({ filePath }, index) => (
                    <div key={index}>
                        <div
                            className={slideItem}
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}/${filePath})`
                            }}
                        ></div>
                    </div>
                ))}
            </Slider>
        )
    }
}

export default withStyles(styles)(HomeCarousel)
