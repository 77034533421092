import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Container,
    Grid,
    Typography,
    withWidth
} from '@material-ui/core'

const styles = theme => ({
    root: {
        margin: theme.spacing(8, 0)
    },
    typography: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    }
})

class Section extends Component {
    render() {
        const {
            title,
            description,
            children,
            width
        } = this.props
        const {
            root,
            typography
        } = this.props.classes

        return (
            <div>
                <div className={root}>
                    <Container>
                        <Box mb={8}>
                            <Typography
                                variant={width === 'xs' ? 'h6' : 'h5'}
                                component="h2"
                                className={typography}
                            >
                                {title}
                            </Typography>
                            {description &&
                                <Grid
                                    justify="center"
                                    container
                                >
                                    <Grid
                                        xs={12}
                                        sm={9}
                                        lg={7}
                                        item
                                    >
                                        <Box mt={3}>
                                            <Typography
                                                align="center"
                                                variant="subtitle2"
                                            >
                                                {description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                        {children}
                    </Container>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withWidth()(Section))
