import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Drawer,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core'
import { ThemeContext } from './../theme/context'

const drawerWidth = 260

const styles = theme => ({
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#37c3f7'
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    listItemActive: {
        backgroundColor: theme.palette.action.selected
    },
    listItemText: {
        color: '#fff'
    }
})

class MainDrawer extends Component {
    static contextType = ThemeContext

    render() {
        const {
            drawer,
            toggleDrawer
        } = this.context
        const {
            drawerPaper,
            list,
            listItemActive,
            listItemText
        } = this.props.classes

        const Link = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />)

        return (
            <div>
                <Drawer
                    anchor="left"
                    variant="temporary"
                    open={drawer}
                    onClose={toggleDrawer}
                    classes={{
                        paper: drawerPaper
                    }}
                >
                    <List
                        className={list}
                        component="nav"
                    >
                        <ListItem
                            to="/"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Нүүр"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                        <ListItem
                            to="/about/us"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Бид"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                        <ListItem
                            to="/videos"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Бүтээл"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                        <ListItem
                            to="/products"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Бүтээгдэхүүн"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                        <ListItem
                            to="/posts"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Зөвлөгөө"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                        <ListItem
                            to="/lessons"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Багш нарт"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                        <ListItem
                            to="/questions"
                            component={Link}
                            activeClassName={listItemActive}
                            button
                            exact
                        >
                            <ListItemText
                                primary="Түгээмэл асуулт"
                                classes={{
                                    primary: listItemText
                                }}
                            />
                        </ListItem>
                    </List>
                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(MainDrawer)
