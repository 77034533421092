import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Container,
    Typography
} from '@material-ui/core'
import { BaseButton } from './../components'

const styles = theme => ({
    typography: {
        margin: theme.spacing(4, 0)
    }
})

class NotFound extends Component {
    render() {
        const { typography } = this.props.classes
        return (
            <div>
                <Container>
                    <Box
                        my={4}
                        textAlign="center"
                    >
                        <img
                            height="180"
                            src="/static/images/user.png"
                            alt="марчаахай"
                        />
                        <Typography
                            className={typography}
                            variant="h6"
                            component="div"
                        >
                            Таны хайсан хуудас байхгүй эсвэл устсан байна
                        </Typography>
                        <div>
                            <BaseButton
                                component={Link}
                                to="/"
                            >
                                Нүүр хуудас руу буцах
                            </BaseButton>
                        </div>
                    </Box>
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)(NotFound)
