import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    root: {
        display: 'block',
        height: 'auto',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
})

class Partner extends Component {
    render() {
        const { image } = this.props
        const { root } = this.props.classes

        return (
            <div>
                <img
                    className={root}
                    src={image}
                    alt="company"
                />
            </div>
        )
    }
}

export default withStyles(styles)(Partner)
