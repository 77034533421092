import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { CardPost } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class HomePosts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            posts: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('posts', {
                progress: true,
                params: {
                    rowsPerPage: 3
                }
            })

            this.setState({
                posts: data
            })
        } catch (err) {
            //
        }
    }

    render() {
        return (
            <Grid
                spacing={4}
                container
            >
                {this.state.posts.map(({ _id, title, coverSrc }) =>
                    <Grid
                        key={_id}
                        xs={12}
                        sm={4}
                        item
                    >
                        <CardPost
                            id={_id}
                            title={title}
                            imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                        />
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default withStyles(styles)(HomePosts)
