import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core'
import {
    BookmarkOutline,
    Reload
} from 'mdi-material-ui'
import { FormatNumber } from './../components'
import { pathJoin } from './../utils/helpers'
import axios from './../plugins/axios'

const styles = theme => ({
    typography: {
        fontSize: 24
    },
    active: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'rgba(224, 5, 67, 0.08)'
        }
    }
})

class Player extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loop: false,
            favorite: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { id } = this.props
        const { exists } = await axios.get(pathJoin('check', 'favorites', id), { progress: true })

        this.setState({
            favorite: exists
        })
    }

    toggleLoop = () => {
        this.setState(({ loop }) => ({
            loop: !loop
        }))
    }

    addFavorite = async () => {
        try {
            const { id } = this.props
            await axios.post(pathJoin('favorites', id), {}, { progress: true })

            this.setState({
                favorite: true
            })
        } catch (err) {
            //
        }
    }

    removeFavorite = async () => {
        try {
            const { id } = this.props
            await axios.delete(pathJoin('favorites', id), { progress: true })

            this.setState({
                favorite: false
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            loop,
            favorite
        } = this.state
        const {
            name,
            videoSrc,
            views,
            onEnded
        } = this.props
        const {
            typography,
            active
        } = this.props.classes

        return (
            <div>
                <ReactPlayer
                    url={videoSrc}
                    loop={loop}
                    onEnded={onEnded}
                    config={{
                        file: {
                            // forceHLS: true
                        }
                    }}
                    stopOnUnmount={false}
                    width="100%"
                    height="auto"
                    controls
                    playing
                    pip
                />
                <Box my={1}>
                    <Grid
                        spacing={2}
                        justify="space-between"
                        container
                    >
                        <Grid item>
                            <Typography
                                variant="h6"
                                component="h1"
                                className={typography}
                            >
                                {name}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="span"
                                color="textSecondary"
                            >
                                <FormatNumber value={views} /> үзсэн
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={this.toggleLoop}
                                className={classNames({
                                    [active]: loop
                                })}
                            >
                                <Reload fontSize="small" />
                            </IconButton>
                            <IconButton
                                onClick={favorite ? this.removeFavorite : this.addFavorite}
                                className={classNames({
                                    [active]: favorite
                                })}
                            >
                                <BookmarkOutline fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }
}

export default withStyles(styles)(Player)
