import React, { Component } from 'react'
import NumberFormat from 'react-number-format'

export default class FormatNumber extends Component {
    static defaultProps = {
        suffix: ''
    }

    render() {
        const {
            value,
            suffix
        } = this.props

        return (
            <NumberFormat
                displayType="text"
                value={value}
                suffix={suffix}
                thousandSeparator={true}
            />
        )
    }
}
