import React, { Component } from 'react'
import {
    Grid,
    Hidden,
    Typography
} from '@material-ui/core'
import { CardPlan } from './../components'

export default class HomePlan extends Component {
    render() {
        return (
            <div>
                <Grid
                    spacing={4}
                    alignItems="center"
                    container
                >
                    <Hidden xsDown>
                        <Grid
                            sm={7}
                            item
                        >
                            <Typography align="center">
                                Та "Марчаахай" онлайн системийн үндсэн хэрэглэгч болсноор "марчаахай" аппликейшн болон веб сайтаар дамжуулан хүүхдийн танин мэдэхүйн контентуудыг 6 сарын хугацаанд хязгааргүй үзэх боломжтой болно.
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Grid
                        sm={5}
                        item
                    >
                        <CardPlan />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
