import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const effect = {
    transform: 'scale(1.03)'
}

const styles = theme => ({
    card: {
        position: 'relative',
        borderRadius: 20,
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': effect,
        '&:focus': effect
    },
    cardImage: {
        width: '100%',
        paddingTop: '70%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:after': {
            content: '" "',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    },
    content: {
        position: 'absolute',
        padding: theme.spacing(2),
        top: '50%',
        left: 0,
        right: 0,
        textAlign: 'center',
        transform: 'translateY(-50%)'
    },
    typography: {
        color: '#fff',
        textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)'
    }
})

class CardProduct extends Component {
    render() {
        const {
            id,
            title,
            imageSrc
        } = this.props
        const {
            card,
            cardImage,
            content,
            typography
        } = this.props.classes

        return (
            <div>
                <Link to={`/products/${id}`}>
                    <div
                        className={card}
                        title={title}
                    >
                        <div
                            className={cardImage}
                            style={{
                                backgroundImage: `url(https://images.weserv.nl/?url=${imageSrc}&w=390&q=100)`
                            }}
                        ></div>
                        <div className={content}>
                            <Typography
                                className={typography}
                                variant="h6"
                                component="h2"
                                noWrap
                            >
                                {title}
                            </Typography>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

export default withStyles(styles)(CardProduct)
