import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { withStyles } from '@material-ui/styles'
import {
    Chip,
    Typography,
    withWidth
} from '@material-ui/core'

const effect = {
    transform: 'scale(1.03)'
}

const styles = theme => ({
    card: {
        display: 'block',
        position: 'relative',
        borderRadius: 4,
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:focus': effect,
        '&:hover': effect,
        '&:hover $title': {
            transform: 'translateY(100%)'
        }
    },
    cardImage: {
        position: 'relative',
        paddingBottom: '80%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1
    },
    border: {
        borderRadius: 20,
        border: '8px solid #fff',
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    },
    title: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        padding: theme.spacing(1),
        textAlign: 'center',
        backgroundColor: '#00b6a7',
        color: '#fff',
        transform: 'translateY(0)',
        transition: 'transform 0.3s',
        zIndex: 2
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        backgroundColor: '#ffc700',
        zIndex: 2
    }
})

class CardVideo extends Component {
    static defaultProps = {
        hasBorder: false
    }

    render() {
        const {
            id,
            name,
            imageSrc,
            isFree,
            hasBorder,
            width
        } = this.props
        const {
            card,
            cardImage,
            border,
            title,
            chip
        } = this.props.classes

        return (
            <div>
                <Link to={`/videos/${id}`}>
                    <div
                        className={classNames(card, {
                            [border]: hasBorder
                        })}
                        title={name}
                    >
                        {isFree &&
                            <Chip
                                size={width === 'xs' ? 'small' : 'medium'}
                                label="Үнэгүй"
                                color="primary"
                                className={chip}
                            />
                        }
                        <div
                            className={cardImage}
                            style={{
                                backgroundImage: `url(https://images.weserv.nl/?url=${imageSrc}&w=370&q=100)`
                            }}
                        ></div>
                        <Typography
                            className={title}
                            variant={width === 'xs' ? 'subtitle2' : 'subtitle1'}
                            component="div"
                            noWrap
                        >
                            {name}
                        </Typography>
                    </div>
                </Link>
            </div>
        )
    }
}

export default withStyles(styles)(withWidth()(CardVideo))
