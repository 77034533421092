import React, { Component } from 'react'
import queryString from 'query-string'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Hidden,
    Link,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Typography,
    Snackbar,
    SnackbarContent,
    InputAdornment
} from '@material-ui/core'
import axios from './../plugins/axios'
import {
    BaseButton,
    BasePasswordField
} from './../components'

const styles = theme => ({
    snackbar: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 400
        }
    },
    image: {
        display: 'block',
        minHeight: '100vh',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom'
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class Register extends Component {
    constructor(props) {
        super(props)

        this.state = {
            countryCodes: [],
            email: '',
            countryCode: '+976',
            phone: '',
            password: '',
            passwordConfirm: '',
            errors: {},
            submitting: false,
            open: false,
            tab: 0
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const countryCodes = await axios.get('country/codes', {
                progress: true
            })

            this.setState({ countryCodes })
        } catch (err) {
            //
        }
    }

    changeTab = (e, value) => {
        this.reset()
        this.setState({
            tab: value
        })
    }

    reset = () => {
        this.setState({
            email: '',
            countryCode: '+976',
            phone: '',
            password: '',
            passwordConfirm: '',
            errors: {},
            confirm: false,
            submitting: false
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    handleCheckBoxChange = name => (e, checked) => {
        this.setState({
            [name]: checked
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    register = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const {
            email,
            countryCode,
            phone,
            password,
            passwordConfirm,
            tab
        } = this.state

        let data = {
            password,
            passwordConfirm
        }

        if (tab === 0) {
            data = Object.assign(data, {
                email,
                password,
                passwordConfirm
            })
        } else {
            data = {
                countryCode,
                phone
            }
        }

        const { history } = this.props
        const uri = tab === 0
            ? 'register/email'
            : 'request/verification'

        try {
            await axios.post(uri, data, {
                progress: true
            })

            if (tab === 0) {
                this.reset()
                this.setState({
                    open: true
                })
            } else {
                const search = queryString.stringify({
                    countryCode,
                    phone
                })

                history.push({
                    pathname: '/verify/phone',
                    search
                })
            }
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            countryCodes,
            email,
            countryCode,
            phone,
            password,
            passwordConfirm,
            errors,
            confirm,
            submitting,
            open,
            tab
        } = this.state
        const {
            image,
            snackbar,
            formControl
        } = this.props.classes

        return (
            <div>
                <Snackbar
                    open={open}
                    onClose={this.closeSnackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <SnackbarContent
                        className={snackbar}
                        message={
                            <span>
                                Та амжилттай бүртгүүллээ.
                            </span>
                        }
                        action={[
                            <Button
                                color="primary"
                                size="small"
                                component={RouterLink}
                                to="/login"
                            >
                                Нэвтрэх
                            </Button>
                        ]}
                    />
                </Snackbar>
                <Grid
                    alignItems="center"
                    justify="center"
                    container
                >
                    <Grid
                        xs={12}
                        sm={8}
                        md={6}
                        lg={5}
                        xl={4}
                        item
                    >
                        <Box p={4}>
                            <form
                                onSubmit={this.register}
                                autoComplete="off"
                                noValidate
                            >
                                <Box
                                    mb={4}
                                    textAlign="center"
                                >
                                    <RouterLink to="/">
                                        <img
                                            height="130"
                                            src="/static/images/user.png"
                                            alt="marchaakhai"
                                        />
                                    </RouterLink>
                                </Box>
                                <Box mb={4}>
                                    <Typography
                                        align="center"
                                        variant="subtitle2"
                                    >
                                        Хэрэв та бүртгэлтэй бол <Link component={RouterLink} to="/login">энд</Link> <br /> дарж нэвтэрнэ үү
                                    </Typography>
                                </Box>
                                {/* Uncomment to show phone number registration form */}
                                {/* <Box my={3}>
                                    <Tabs
                                        value={tab}
                                        onChange={this.changeTab}
                                        variant="fullWidth"
                                        textColor="primary"
                                        indicatorColor="primary"
                                    >
                                        <Tab
                                            id="email"
                                            label="И-мэйл хаяг"
                                        />
                                        <Tab
                                            id="phone-number"
                                            label="Утас"
                                        />
                                    </Tabs>
                                </Box> */}
                                {tab === 0 && (
                                    <div>
                                        <TextField
                                            label="И-мэйл хаяг"
                                            value={email}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            onChange={this.handleChange('email')}
                                            type="email"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            autoFocus
                                        />
                                        <BasePasswordField
                                            label="Нууц үг"
                                            value={password}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                            onChange={this.handleChange('password')}
                                        />
                                        <TextField
                                            label="Нууц үг дахин оруулна уу"
                                            value={passwordConfirm}
                                            onChange={this.handleChange('passwordConfirm')}
                                            type="password"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </div>
                                )}
                                {tab === 1 && (
                                    <div>
                                        <TextField
                                            value={countryCode}
                                            onChange={this.handleChange('countryCode')}
                                            variant="outlined"
                                            margin="normal"
                                            children={countryCodes.map(({ name, code }, index) =>
                                                <MenuItem
                                                    value={code}
                                                    key={index}
                                                >
                                                    {name} ({code})
                                                </MenuItem>
                                            )}
                                            fullWidth
                                            select
                                        />
                                        <TextField
                                            value={phone}
                                            error={!!errors.phone}
                                            helperText={errors.phone}
                                            onChange={this.handleChange('phone')}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>
                                            }}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            autoFocus
                                        />
                                    </div>
                                )}
                                <div className={formControl}>
                                    <FormControlLabel
                                        label={
                                            <div>
                                                <Link component={RouterLink} to="/terms/conditions">Үйлчилгээний нөхцөл</Link> зөвшөөрөх
                                            </div>
                                        }
                                        control={
                                            <Checkbox
                                                checked={confirm}
                                                onChange={this.handleCheckBoxChange('confirm')}
                                            />
                                        }
                                    />
                                </div>
                                <Box mt={2}>
                                    <BaseButton
                                        type="submit"
                                        disabled={!confirm || submitting}
                                    >
                                        Бүртгүүлэх
                                    </BaseButton>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        <Grid
                            lg={7}
                            xl={8}
                            item
                        >
                            <div
                                className={image}
                                style={{
                                    backgroundImage: 'url(/static/images/login-bg.png)'
                                }}
                            ></div>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Register)
