import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Container,
    Typography
} from '@material-ui/core'
import { App } from './../layouts'
import { OpenGraph } from './../components'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

const styles = theme => ({
    image: {
        display: 'block',
        width: '100%'
    },
    postBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '30px'
    }
})

class Product extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            coverSrc: '',
            body: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { params } = this.props.match
            const { id } = params

            const { product } = await axios.get(pathJoin('products', id), { progress: true })
            const {
                title,
                coverSrc,
                body
            } = product

            this.setState({
                title,
                coverSrc,
                body
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            title,
            coverSrc,
            body
        } = this.state
        const {
            image,
            postBody
        } = this.props.classes
        const imageSrc = `https://images.weserv.nl/?url=${process.env.REACT_APP_API_URL}/${coverSrc}&w=1920&q=100`
        const url = window.location.href

        return (
            <App>
                <OpenGraph
                    title={title}
                    url={url}
                    image={imageSrc}
                />
                <img
                    className={image}
                    src={imageSrc}
                    alt={title}
                />
                <Box my={4}>
                    <Container maxWidth="sm">
                        <Box my={1}>
                            <Typography
                                variant="h5"
                                component="h1"
                            >
                                {title}
                            </Typography>
                        </Box>
                        <div
                            className={postBody}
                            dangerouslySetInnerHTML={{
                                __html: body
                            }}
                        ></div>
                    </Container>
                </Box>
            </App>
        )
    }
}

export default withStyles(styles)(Product)
