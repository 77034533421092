import React, { Component } from 'react'
import Moment from 'react-moment'

export default class FormatDate extends Component {
    render() {
        return (
            <Moment
                locale="mn"
                format="MMM[ын] D, YYYY"
            >
                {this.props.date}
            </Moment>
        )
    }
}
