import {
    SET_AUTH,
    REMOVE_AUTH,
    SET_USER,
    SET_TOKEN
} from './actionTypes'

const setAuth = (user, token) => ({
    type: SET_AUTH,
    payload: {
        user,
        token
    }
})

const removeAuth = () => ({
    type: REMOVE_AUTH
})

const setUser = user => ({
    type: SET_USER,
    payload: {
        user
    }
})

const setToken = token => ({
    type: SET_TOKEN,
    payload: {
        token
    }
})

export {
    setAuth,
    removeAuth,
    setUser,
    setToken
}
