import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class OpenGraph extends Component {
    static defaultProps = {
        type: 'website',
        description: ''
    }

    render() {
        const {
            title,
            type,
            description,
            url,
            image
        } = this.props

        return (
            <Helmet>
                <meta property="og:title" content={title} />
                <meta property="og:type" content={type} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={url} />
                <meta property="og:image" content={image} />
            </Helmet>
        )
    }
}
