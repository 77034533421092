import React, { Component } from 'react'
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Container,
    Link,
    Typography
} from '@material-ui/core'
import { App } from './../layouts'
import {
    FormatDate,
    OpenGraph
} from './../components'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

const styles = theme => ({
    image: {
        display: 'block',
        width: '100%'
    },
    postBody: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '30px'
    },
    socialButton: {
        display: 'inline-block',
        cursor: 'pointer',
        outline: 0
    }
})

class Post extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            coverSrc: '',
            body: '',
            createdAt: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { params } = this.props.match
            const { id } = params

            const { post } = await axios.get(pathJoin('posts', id), { progress: true })
            const {
                title,
                coverSrc,
                body,
                createdAt
            } = post

            this.setState({
                title,
                coverSrc,
                body,
                createdAt
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            title,
            coverSrc,
            body,
            createdAt
        } = this.state
        const {
            image,
            postBody,
            socialButton
        } = this.props.classes
        const imageSrc = `https://images.weserv.nl/?url=${process.env.REACT_APP_API_URL}/${coverSrc}&w=1920&q=100`
        const url = window.location.href

        return (
            <App>
                <OpenGraph
                    title={title}
                    url={url}
                    image={imageSrc}
                />
                <img
                    className={image}
                    src={imageSrc}
                    alt={title}
                />
                <Box my={4}>
                    <Container maxWidth="sm">
                        {createdAt &&
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                <FormatDate date={createdAt} />
                            </Typography>
                        }
                        <Box my={1}>
                            <Typography
                                variant="h5"
                                component="h1"
                            >
                                {title}
                            </Typography>
                        </Box>
                        <div
                            className={postBody}
                            dangerouslySetInnerHTML={{
                                __html: body
                            }}
                        ></div>
                        <Box mt={4}>
                            <Link
                                className={socialButton}
                                component={FacebookShareButton}
                                url={url}
                            >
                                <FacebookIcon size={48} />
                            </Link>
                            <Link
                                className={socialButton}
                                component={TwitterShareButton}
                                url={url}
                            >
                                <TwitterIcon size={48} />
                            </Link>
                        </Box>
                    </Container>
                </Box>
            </App>
        )
    }
}

export default withStyles(styles)(Post)
