import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Card,
    CardMedia,
    Grid,
    Typography
} from '@material-ui/core'
import { BaseButton } from './../components'

const styles = theme => ({
    card: {
        borderRadius: 20,
        boxShadow: 'none'
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    }
})

class HomeUseCoupon extends Component {
    render() {
        const {
            card,
            media
        } = this.props.classes

        return (
            <div>
                <Grid
                    justify="center"
                    container
                >
                    <Grid
                        xs={12}
                        sm={8}
                        md={6}
                        lg={5}
                        item
                    >
                        <Card className={card}>
                            <CardMedia
                                className={media}
                                image="/static/images/coupon.jpg"
                                title="Бэлгийн карт"
                            />
                        </Card>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10}
                        lg={9}
                        item
                    >
                        <Box my={5}>
                            <Typography align="center">
                                Та "Марчаахай" бэлгийн картын серийн дугаарыг оруулж багцаа идэвхжүүлэн "Марчаахай" вэб, "Марчаахай" гар утасны аппликейшнд байрлах хүүхдийн уран бүтээлийг үзээрэй.
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <BaseButton
                                component={Link}
                                to="/use/coupon"
                            >
                                Бэлгийн карт ашиглах
                            </BaseButton>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(HomeUseCoupon)
