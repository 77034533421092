import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Card,
    CardContent,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText
} from '@material-ui/core'
import { Pencil } from 'mdi-material-ui'
import { Avatar } from './../components'

const styles = theme => ({
    card: {
        height: '100%',
        borderRadius: 20,
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    },
    listItem: {
        minHeight: 84
    }
})

class Profile extends Component {
    render() {
        const {
            user,
            classes
        } = this.props
        const {
            card,
            listItem
        } = classes
        const {
            name,
            avatar,
            phone,
            email
        } = user

        return (
            <Card className={card}>
                <CardContent>
                    <Box
                        mt={1}
                        textAlign="center"
                    >
                        <Avatar file={avatar} />
                    </Box>
                    <Box mt={4}>
                        <List>
                            <ListItem
                                className={listItem}
                                divider
                            >
                                <ListItemText
                                    primary="Нэр"
                                    secondary={name}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        component={Link}
                                        to="/update/profile"
                                        edge="end"
                                    >
                                        <Pencil fontSize="small" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem
                                className={listItem}
                                divider
                            >
                                <ListItemText
                                    primary="И-мэйл хаяг"
                                    secondary={email}
                                />
                            </ListItem>
                            <ListItem
                                className={listItem}
                                divider
                            >
                                <ListItemText
                                    primary="Утас"
                                    secondary={phone}
                                />
                            </ListItem>
                            <ListItem className={listItem}>
                                <ListItemText primary="Нууц үг" />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        component={Link}
                                        to="/change/password"
                                        edge="end"
                                    >
                                        <Pencil fontSize="small" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Box>
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(withStyles(styles)(Profile))
