import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Grid,
    Typography
} from '@material-ui/core'
import { ChevronDown } from 'mdi-material-ui'
import { App } from './../layouts'
import { Section } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    card: {
        overflow: 'hidden',
        borderRadius: 8,
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    },
    accordion: {
        margin: '0 !important',
        borderRadius: 0,
        boxShadow: 'none'
    }
})

class Questions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questions: []
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    fetchData = async (page = 1) => {
        try {
            const { data } = await axios.get('questions', {
                progress: true,
                params: {
                    sortBy: 'createdAt',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                questions: data
            }, () => {
                window.scrollTo(0, 0)
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            card,
            accordion
        } = this.props.classes
        const { questions } = this.state

        return (
            <App>
                <Section title="Түгээмэл асуулт">
                    <Grid
                        spacing={4}
                        justify="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            lg={6}
                            item
                        >
                            <div className={card}>
                                {questions.map(({ _id, key, value }, index) =>
                                    <div key={_id}>
                                        <Accordion className={accordion}>
                                            <AccordionSummary expandIcon={<ChevronDown />}>
                                                <Typography >
                                                    {key}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography color="textSecondary">
                                                    <span dangerouslySetInnerHTML={{ __html: value }}></span>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        {index !== questions.length - 1 && (
                                            <Divider />
                                        )}
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(Questions)
