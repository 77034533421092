import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    withWidth
} from '@material-ui/core'
import {
    BaseButton,
    CardVideo
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class HomeVideos extends Component {
    constructor(props) {
        super(props)

        this.state = {
            videos: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('videos', {
                progress: true,
                params: {
                    rowsPerPage: 8
                }
            })

            this.setState({
                videos: data
            })
        } catch (err) {

        }
    }

    render() {
        return (
            <div>
                <Grid
                    spacing={this.props.width === 'xs' ? 2 : 4}
                    container
                >
                    {this.state.videos.map(({ _id, name, coverSrc, isFree }) =>
                        <Grid
                            key={_id}
                            xs={6}
                            sm={4}
                            lg={3}
                            item
                        >
                            <CardVideo
                                id={_id}
                                name={name}
                                imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                isFree={isFree}
                                hasBorder
                            />
                        </Grid>
                    )}
                </Grid>
                <Box
                    mt={8}
                    textAlign="center"
                >
                    <BaseButton
                        component={Link}
                        to="/videos"
                    >
                        Цааш үзэх
                    </BaseButton>
                </Box>
            </div>
        )
    }
}

export default withStyles(styles)(withWidth()(HomeVideos))
