import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    TextField,
    Snackbar,
    SnackbarContent,
    IconButton
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Close } from 'mdi-material-ui'
import { setUser } from './../redux/auth/actions'
import { Authenticate } from './../layouts'
import {
    BaseButton,
    Section
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    snackbar: {
        backgroundColor: green[600]
    }
})

class UseCoupon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            code: '',
            errors: {},
            submitting: false,
            open: false
        }
    }

    reset = () => {
        this.setState({
            code: '',
            errors: {},
            submitting: false
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    updateUser = async () => {
        const { setUser } = this.props
        const { user } = await axios.get('/users/me')
        setUser(user)
    }

    submit = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        try {
            const { code } = this.state
            await axios.post('/use/coupon', { code })

            this.updateUser()
            this.reset()
            this.setState({
                open: true
            })
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            code,
            errors,
            submitting,
            open
        } = this.state
        const { snackbar } = this.props.classes

        return (
            <Authenticate>
                <Snackbar
                    open={open}
                    onClose={this.closeSnackbar}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <SnackbarContent
                        className={snackbar}
                        message={
                            <span>
                                Таны видео үзэх эрх амжилттай сунгагдлаа.
                            </span>
                        }
                        action={[
                            <IconButton
                                color="inherit"
                                onClick={this.closeSnackbar}
                            >
                                <Close />
                            </IconButton>
                        ]}
                    />
                </Snackbar>
                <Section title="Бэлгийн карт">
                    <Grid
                        justify="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={6}
                            lg={5}
                            item
                        >
                            <form
                                onSubmit={this.submit}
                                autoComplete="off"
                                noValidate
                            >
                                <TextField
                                    label="Серийн дугаар"
                                    value={code}
                                    error={!!errors.code}
                                    helperText={errors.code}
                                    onChange={this.handleChange('code')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <Box
                                    mt={2}
                                    textAlign="right"
                                >
                                    <BaseButton
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Ашиглах
                                    </BaseButton>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

const mapDispatchToProps = {
    setUser
}

const component = withStyles(styles)(UseCoupon)

export default connect(
    null,
    mapDispatchToProps
)(component)
