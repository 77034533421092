import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'
import {
    Box,
    Button,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@material-ui/core'
import { FormatDate } from './../components'

const styles = theme => ({
    card: {
        height: '100%',
        borderRadius: 20,
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    }
})

class ProfilePlanStatus extends Component {
    render() {
        const { expiryDate } = this.props
        const { card } = this.props.classes

        const active = expiryDate && moment().isBefore(expiryDate)

        const activeItem = (
            <div>
                <Box
                    mb={4}
                    textAlign="center"
                >
                    <img
                        width="260"
                        src="/static/images/active.png"
                        alt="active"
                    />
                </Box>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Багц дуусах:"
                            secondary={<FormatDate date={expiryDate} />}
                        />
                        <ListItemSecondaryAction>
                            <Button
                                to="/buy/plan"
                                color="primary"
                                component={Link}
                            >
                                Сунгах
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </div>
        )

        const inactiveItem = (
            <div>
                <Box
                    mb={4}
                    textAlign="center"
                >
                    <img
                        width="260"
                        src="/static/images/inactive.png"
                        alt="inactive"
                    />
                </Box>
                <Box textAlign="center">
                    <Typography
                        variant="subtitle1"
                        component="div"
                    >
                        Та видео үзэх эрхгүй эсвэл багцын эрх дууссан байна.
                    </Typography>
                    <Box mt={2}>
                        <Button
                            to="/buy/plan"
                            component={Link}
                            color="primary"
                            variant="contained"
                        >
                            Багц авах
                        </Button>
                    </Box>
                </Box>
            </div>
        )

        return (
            <Card className={card}>
                <CardContent>
                    <Box mt={5}>
                        {active ? activeItem : inactiveItem}
                    </Box>
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    expiryDate: state.auth.user.expiryDate
})

export default connect(mapStateToProps)(withStyles(styles)(ProfilePlanStatus))
