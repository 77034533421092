import React, { Component } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Tooltip,
    Typography
} from '@material-ui/core'

const styles = theme => ({
    card: {
        position: 'relative',
        borderRadius: 8,
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    },
    cardImage: {
        width: '100%',
        paddingTop: '56.25%',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        backgroundColor: '#00b6a7',
        zIndex: 2
    },
    notAllowed: {
        cursor: 'not-allowed'
    }
})

class CardLesson extends Component {
    render() {
        const {
            user,
            token,
            id,
            name,
            imageSrc,
            isFree
        } = this.props
        const {
            card,
            cardImage,
            chip,
            notAllowed
        } = this.props.classes
        const active = user && moment().isBefore(user.expiryDate)
        const disabled = isFree ? false : !active
        const href = token
            ? `${process.env.REACT_APP_API_URL}/api/lessons/${id}/download?access_token=${token}`
            : `${process.env.REACT_APP_API_URL}/api/lessons/${id}/download`

        return (
            <div>
                <Tooltip
                    title={disabled ? "Та ҮНДСЭН ХЭРЭГЛЭГЧ болсноор уг файлыг татаж авах эрхтэй болно" : ""}
                    placement="left"
                >
                    <Card
                        className={classNames(card, {
                            [notAllowed]: disabled
                        })}
                    >
                        <CardActionArea
                            href={href}
                            disabled={disabled}
                            target="_blank"
                        >
                            {isFree &&
                                <Chip
                                    label="Үнэгүй"
                                    color="primary"
                                    className={chip}
                                />
                            }
                            <div
                                className={cardImage}
                                style={{
                                    backgroundImage: `url(https://images.weserv.nl/?url=${imageSrc}&w=410&q=100)`
                                }}
                            ></div>
                            <CardContent>
                                <Typography
                                    title={name}
                                    variant="subtitle2"
                                    component="h3"
                                    color="textPrimary"
                                    noWrap
                                >
                                    {name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Tooltip>
            </div>
        )
    }
}

const mapStateToProps = state => state.auth

export default connect(mapStateToProps)(withStyles(styles)(CardLesson))
