import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Card,
    Typography
} from '@material-ui/core'
import { BaseButton } from './../components'

const styles = theme => ({
    card: {
        borderRadius: 20,
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    },
    cardImage: {
        width: '100%',
        paddingTop: '56.25%',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    content: {
        padding: theme.spacing(2),
        backgroundColor: '#fff'
    },
    button: {
        padding: '4px 32px'
    }
})

class CardPost extends Component {
    render() {
        const {
            id,
            title,
            imageSrc
        } = this.props
        const {
            card,
            cardImage,
            content,
            button
        } = this.props.classes

        return (
            <div>
                <Card className={card}>
                    <div
                        className={cardImage}
                        style={{
                            backgroundImage: `url(https://images.weserv.nl/?url=${imageSrc}&w=390&q=100)`
                        }}
                    ></div>
                    <div className={content}>
                        <Typography
                            title={title}
                            variant="subtitle1"
                            component="h3"
                            color="textPrimary"
                            noWrap
                        >
                            {title}
                        </Typography>
                        <Box mt={1}>
                            <BaseButton
                                className={button}
                                component={Link}
                                to={`/posts/${id}`}
                            >
                                Унших
                            </BaseButton>
                        </Box>
                    </div>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(CardPost)
