import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { App } from './../layouts'
import { Section } from './../components'

const styles = theme => ({
    content: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '30px'
    }
})

class DataDeletionInstructions extends Component {
    render() {
        const {
            content
        } = this.props.classes

        return (
            <App>
                <Section title="Facebook Data Deletion Instructions">
                    <div className={content}>
                        <p>According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete your activity data with Leadgogo, please follow the steps below to remove your information:</p>
                    </div>
                    <div className={content}>
                        <ol>
                            <li>Go to Your Facebook Account’s Setting & Privacy and click on "Settings."</li>
                            <li>Go to "Apps and Websites" and you will see all of your Apps activities.</li>
                            <li>Select the option box for "Марчаахай".</li>
                            <li>Click the "Remove" button.</li>
                        </ol>
                    </div>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(DataDeletionInstructions)
