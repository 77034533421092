import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { App } from './../layouts'
import { Section } from './../components'

const styles = theme => ({
    content: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '30px'
    }
})

class TermsConditions extends Component {
    render() {
        const {
            content
        } = this.props.classes

        return (
            <App>
                <Section title="Үйлчилгээний нөхцөл">
                    <div className={content}>
                        <p>Манай www.marchaakhai.mn вэб сайт болон мобайл аппликэйшнийг /цаашид систем гэх/ сонгон хэрэглэж буй эрхэм хэрэглэгч танд талархал илэрхийлье.</p>
                        <p>Энэхүү системээр дамжуулан 2-8 насны хүүхдүүдэд дуу шүлэг, үлгэр, богино хэмжээний зохиомжуудыг хүргэх бөгөөд хүүхдэд танин мэдэхүйн мэдлэг олгож, зөв хүн болж төлөвшихөд нь туслах зорилготой юм.</p>
                        <p>Та манай системийг 6 сар ба 1 жилийн эрхтэйгээр худалдан авах сонголттой бөгөөд тухайн эрхийн хугацаанд "Марчаахай" ХХК-аас гаргасан хүүхдийн контентуудыг хязгааргүй үзэх эрхтэй болно.</p>
                        <p>Та манай онлайн вэб сайтыг ашиглах нь "Марчаахай" ХХК-ийн үйлчилгээг хүлээн зөвшөөрч байгааг бүрэн илтгэнэ.</p>
                    </div>
                    <Typography variant="h6">
                        НЭГ. НИЙТЛЭГ ҮНДЭСЛЭЛ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Систем нь "Марчаахай" ХХК-ний албан ёсны технологийн бүтээгдэхүүн бөгөөд энэхүү нөхцөл, журам нь онлайн "Марчаахай" ХХК-ийн контентуудыг үзэхтэй холбоотой үүсэх харилцааг зохицуулахад оршино.</li>
                            <li>Энэхүү журмын хэрэгжилтэд "Марчаахай" ХХК /цаашид "Компани" гэх/ болон хэрэглэгч хамтран хяналт тавина.</li>
                            <li>Энэхүү нөхцөл нь хэрэглэгч "Марчаахай" ХХК-ын хүүхдийн танин мэдэхүйн контентыг үзэх 6 сар ба 1 жилийн эрх худалдан авахаас өмнө хүлээн зөвшөөрч баталгаажуулсаны үндсэн дээр хэрэгжинэ.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ХОЁР. ОНЛАЙН СИСТЕМ, ТҮҮНИЙ ҮЙЛ АЖИЛЛАГАА
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Системийн тэмдэгтүүд, бүх контентууд нь компанийн өмч бөгөөд энэхүү онлайн үзвэрийн лого, нэр, контентууд, бусад загварыг хуулах, олшруулах, дуурайх, дамлан худалдах, өөр бусад зүйлд хууль бусаар ашиглахыг хориглоно. www.marchaakhai.mn онлайн вэб сайтаас татан авсан, хуулбарлагдсан, ашигласан бүх мэдээллийг Монгол улсын оюуны өмчийн хуулийн дагуу шийдвэрлүүлнэ.</li>
                            <li>Системийн хөтлөлт, түүний аюулгүй байдал, хэрэглэгчдийн мэдээллийн нууцлалыг компани бүрэн хариуцна.</li>
                            <li>Системийн бүх үйл ажиллагаа нь автоматаар хийгдэх бөгөөд контентуудын чанарын шаардлага, байнгын шинэчлэлт, үйл ажиллагаа нь 24 цаг ажиллана. Системийн аливаа алдаа, доголдол, эрчим хүчний саатал, серверийн ачаалал зэргээс шалтгаалсан асуудал үүсэх нөхцөлд онлайн веб сайтыг хэвийн болгоход ажлыг компани хариуцах бөгөөд хэвийн болсон нөхцөлд хэрэглэгчдэд мэдэгдэж байх үүрэгтэй.</li>
                            <li>Систем алдаатай байх үеэр хийгдсэн аливаа захиалгыг оператор хянаж, ямар нэгэн алдаа дутагдал илэрвэл тухайн захиалгыг цуцалсан тухай хэрэглэгчид утсаар буюу и-мэйлээр мэдэгдэн, тухайн захиалгатай холбоотой хийгдсэн төлбөрийг буцаан шилжүүлнэ.</li>
                            <li>Компани вэб сайтын бүх төрлийн мэдээллийн шинэчлэлийг бүрэн хариуцна.</li>
                            <li>Системийг ашиглахад хэрэглэгч таны сонголтод тус болох, мэдээлэл өгөх зорилгоор хэд хэдэн онцлох ангиллыг харуулна.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ГУРАВ. ОНЛАЙН СИСТЕМИЙН ГИШҮҮН БА ТҮҮНИЙ ЭРХ, ҮҮРЭГ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Системд гишүүнээр бүртгүүлэхийн тулд и-мэйл хаяг, нууц үгийг оруулах ба гишүүнээр бүртгүүлсэн тохиолдолд энэхүү журмыг зөвшөөрсөнд тооцно.</li>
                            <li>Гишүүний мэдээллийн үнэн зөвийг хэрэглэгч өөрөө бүрэн хариуцах ба энэхүү мэдээллийн нууцлалыг компани бүрэн хариуцна.</li>
                            <li>Системээр 14 болон түүнээс дээш насны хэрэглэгчид захиалга өгөх эрхтэй. Түүнээс бага насны хэрэглэгч хуурамч захиалга өгсөн тохиолдолд эцэг эх, асран хамгаалагч нь хариуцлагыг бүрэн хүлээнэ.</li>
                            <li>Системийн гишүүн болмогц тухайн хэрэглэгчид эрх үүснэ. Тухайн хэрэглэгчийн эрхэд хувийн мэдээлэл, контент үзэлтийн түүх зэрэг мэдээлэл багтана.</li>
                            <li>Хэрэглэгч нь системийн үйл ажиллагаатай холбоотой санал гомдол, шинэлэг санаа зэргийг мэдэгдэх эрхтэй.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ДӨРӨВ. ТӨЛБӨР ТООЦОО
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Систем нь 6 сар 15,000 төгрөг ба 1 жилийн 28,000 төгрөгөөр хэрэглэгчийн эрх авна.</li>
                            <li>Хэрэглэгч тухайн сарын эрх авсан үед өөрийн эрхээр нэвтэрч үнэ төлбөргүйгээр компанийн гаргасан бүх контентыг үнэгүй үзэх болно.</li>
                            <li>Та төлбөрөө дансаар хийх бөгөөд Монгол улсад үйл ажиллагаа явуулж буй банкнуудын төлбөрийн карт ашиглах, Мобайл гүйлгээний дансаа ашиглан төлбөрийг төлөх олон улсын paypall-аар төлөх боломжтой.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ТАВ. ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛИЙГ СОЛИУЛАХ БОЛОН БУЦААХ НӨХЦӨЛ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Хэрэглэгч системийн төлбөр хийгдсэнээс хойш эрх үүсэх бөгөөд үүссэн эрх 3 хоногийн дотор үйлчилгээг буцаах болон солиулах эрхтэй бөгөөд түүнээс хойш бол буцаах боломжгүй болно.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ЗУРГАА. ХАРИУЦЛАГА
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Хэрэглэгч төлбөр төлөхдөө буруу данс руу шилжүүлэх, буруу захиалга хийх зэрэг тохиолдолд компани хариуцлага хүлээхгүй.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ДОЛОО. БУСАД ЗААЛТ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Хэрэглэгчийн санал хүсэлт, асуулт, лавлагааг info@marchaakhai.mn и-мэйл хаяг болон Марчаахай фэйсбүүк хуудас, чат ашиглан, мөн (+976) 72725555 дугаарын утсаар хүлээн авна.</li>
                            <li>Хэрэглэгчээс гарсан гомдол, маргаантай асуудлыг вэб сайтын нөхцөл, нууцлалын баталгаа болон Монгол улсын хууль дүрмийн дагуу шийдвэрлэнэ.</li>
                            <li>Компани нь бүтээгдэхүүний нөхцөл журамд өөрчлөлт оруулах эрхтэй ба тухайн өөрчлөлт нь тэр даруй хүчинтэй болно. Тухайн шинэ нөхцөл байдалтай танилцах нь таны хариуцлага ба та компанийн систем болон бусад бүх нөхцөлүүдэд бүрэн болон бүрэн бус хэмжээгээр өөрчлөлт хийх, устгах эрхгүй.</li>
                        </ol>
                    </div>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(TermsConditions)
