import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Container
} from '@material-ui/core'
import { App } from './../layouts'
import {
    HomeCarousel,
    HomeCategories,
    HomePartners,
    HomePlan,
    HomePosts,
    HomeUseCoupon,
    HomeVideos,
    Section,
    SectionWaves
} from './../components'

const styles = theme => ({
    //
})

class Home extends Component {
    render() {
        return (
            <App>
                <HomeCarousel />
                <Section title="Бүтээл">
                    <HomeCategories />
                </Section>
                <SectionWaves title="Шинээр нэмэгдсэн">
                    <HomeVideos />
                </SectionWaves>
                <Section title="Зөвлөгөө">
                    <HomePosts />
                </Section>
                <SectionWaves title="Үнийн санал">
                    <HomePlan />
                </SectionWaves>
                <Box my={8}>
                    <Container>
                        <HomeUseCoupon />
                    </Container>
                </Box>
                <Box pt={2}>
                    <Section title="Хамтрагчид">
                        <HomePartners />
                    </Section>
                </Box>
            </App>
        )
    }
}

export default withStyles(styles)(Home)
