import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Hidden,
    IconButton,
    Link,
    Toolbar,
    Typography
} from '@material-ui/core'
import {
    Facebook,
    Instagram,
    Youtube
} from 'mdi-material-ui'

const effect = {
    transform: 'scale(1.05)'
}

const styles = theme => ({
    button: {
        display: 'inline-block',
        transition: 'transform 0.3s',
        '&:hover': effect,
        '&:focus': effect
    },
    menuButton: {
        fontSize: 18,
        textTransform: 'none',
        margin: theme.spacing(0, 1)
    },
    socialButton: {
        fontSize: 22,
        border: '1px solid rgba(0, 0, 0, 0.12)'
    }
})

class MainFooter extends Component {
    currentYear = () => {
        return new Date().getFullYear()
    }

    render() {
        const {
            button,
            menuButton,
            socialButton
        } = this.props.classes
        const {
            REACT_APP_APP_STORE_URL,
            REACT_APP_GOOGLE_PLAY_URL,
            REACT_APP_FACEBOOK,
            REACT_APP_INSTAGRAM,
            REACT_APP_YOUTUBE
        } = process.env
        const currentYear = this.currentYear()

        return (
            <div>
                <Box mt={8}>
                    <Divider />
                    <Container>
                        <Box
                            my={4}
                            textAlign="center"
                        >
                            <img
                                height="120"
                                src="/static/images/user.png"
                                alt="марчаахай"
                            />
                        </Box>
                        <Hidden xsDown>
                            <Grid
                                spacing={1}
                                justify="center"
                                container
                            >
                                <Grid item>
                                    <Link
                                        className={button}
                                        href={REACT_APP_APP_STORE_URL}
                                    >
                                        <img
                                            height="45"
                                            src="/static/images/app-store-badge.png"
                                            alt="app store badge"
                                        />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        className={button}
                                        href={REACT_APP_GOOGLE_PLAY_URL}
                                    >
                                        <img
                                            height="45"
                                            src="/static/images/google-play-badge.png"
                                            alt="google play badge"
                                        />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Box my={2}>
                                <Toolbar
                                    style={{
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Button
                                        className={menuButton}
                                        component={RouterLink}
                                        color="inherit"
                                        to="/terms/conditions"
                                    >
                                        Үйлчилгээний нөхцөл
                                    </Button>
                                    <Button
                                        className={menuButton}
                                        component={RouterLink}
                                        color="inherit"
                                        to="/privacy/policy"
                                    >
                                        Нууцлалын бодлого
                                    </Button>
                                    <Button
                                        className={menuButton}
                                        component={RouterLink}
                                        color="inherit"
                                        to="/facebook-data-deletion-instructions"
                                    >
                                        Facebook Data Deletion Instructions
                                    </Button>
                                </Toolbar>
                            </Box>
                        </Hidden>
                        <Box my={2}>
                            <Grid
                                spacing={2}
                                justify="center"
                                container
                            >
                                <Grid item>
                                    <IconButton
                                        className={socialButton}
                                        href={REACT_APP_INSTAGRAM}
                                    >
                                        <Instagram fontSize="inherit" />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        className={socialButton}
                                        href={REACT_APP_FACEBOOK}
                                    >
                                        <Facebook fontSize="inherit" />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        className={socialButton}
                                        href={REACT_APP_YOUTUBE}
                                    >
                                        <Youtube fontSize="inherit" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            my={4}
                            textAlign="center"
                        >
                            <Typography
                                variant="body2"
                                component="div"
                                color="textSecondary"
                            >
                                &copy; 2013-{currentYear} Марчаахай ХХК. Зохиогчийн эрхээр хамгаалагдсан.
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default withStyles(styles)(MainFooter)
