import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Grid,
    Hidden,
    TextField,
    Typography,
    Snackbar,
    SnackbarContent,
    IconButton
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Close } from 'mdi-material-ui'
import axios from './../plugins/axios'
import { BaseButton } from './../components'

const styles = theme => ({
    snackbar: {
        backgroundColor: green[600]
    },
    image: {
        display: 'block',
        minHeight: '100vh',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom'
    }
})

class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            errors: {},
            submitting: false
        }
    }

    reset = () => {
        this.setState({
            username: '',
            errors: {},
            submitting: false,
            open: false
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    submit = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const data = {
            username: this.state.username
        }

        try {
            await axios.post('forgot/password', data, {
                progress: true
            })

            this.setState({
                submitting: false,
                open: true
            })
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            username,
            errors,
            submitting,
            open
        } = this.state
        const {
            snackbar,
            image
        } = this.props.classes

        return (
            <div>
                <Snackbar
                    open={open}
                    onClose={this.closeSnackbar}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <SnackbarContent
                        className={snackbar}
                        message={
                            <span>
                                Таны и-мэйл хаяг, утасны дугаар луу нэвтрэх нууц үг илгээлээ.
                            </span>
                        }
                        action={[
                            <IconButton
                                color="inherit"
                                onClick={this.closeSnackbar}
                            >
                                <Close />
                            </IconButton>
                        ]}
                    />
                </Snackbar>
                <Grid
                    alignItems="center"
                    justify="center"
                    container
                >
                    <Grid
                        xs={12}
                        sm={8}
                        md={6}
                        lg={5}
                        xl={4}
                        item
                    >
                        <Box p={4}>
                            <form
                                onSubmit={this.submit}
                                autoComplete="off"
                                noValidate
                            >
                                <Box
                                    mb={4}
                                    textAlign="center"
                                >
                                    <RouterLink to="/">
                                        <img
                                            height="130"
                                            src="/static/images/user.png"
                                            alt="marchaakhai"
                                        />
                                    </RouterLink>
                                </Box>
                                <Box mb={4}>
                                    <Typography
                                        align="center"
                                        variant="subtitle2"
                                    >
                                        Бүртгэлтэй и-мэйл хаяг эсвэл утасны <br /> дугаараа оруулна уу.
                                    </Typography>
                                </Box>
                                <TextField
                                    label="И-мэйл хаяг эсвэл утас"
                                    value={username}
                                    error={!!errors.username}
                                    helperText={errors.username}
                                    onChange={this.handleChange('username')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                />
                                <Box mt={2}>
                                    <Grid
                                        alignItems="center"
                                        justify="space-between"
                                        container
                                    >
                                        <Grid item>
                                            <BaseButton
                                                type="submit"
                                                disabled={submitting}
                                            >
                                                Нууц үг шинэчлэх
                                            </BaseButton>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                component={RouterLink}
                                                to="/login"
                                            >
                                                Буцах
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        <Grid
                            lg={7}
                            xl={8}
                            item
                        >
                            <div
                                className={image}
                                style={{
                                    backgroundImage: 'url(/static/images/login-bg.png)'
                                }}
                            ></div>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(ForgotPassword)
