import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField
} from '@material-ui/core'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class LessonFilters extends Component {
    constructor(props) {
        super(props)

        this.state = {
            grade: '',
            subject: '',
            grades: [],
            subjects: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    setGrade = e => {
        const { value } = e.target
        const { subjects } = this.state.grades.find(item => item._id === value)

        this.setState({
            grade: value,
            subject: '',
            subjects
        })
    }

    setValue = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.props.onSubmit(null)
        this.setState({
            grade: '',
            subject: '',
            subjects: []
        })
    }

    fetchData = async () => {
        try {
            const {
                data
            } = await axios.get('grades', {
                progress: true,
                params: {
                    sortBy: 'name',
                    sortOrder: 'asc',
                    populate: [
                        'subjects'
                    ]
                }
            })

            this.setState({
                grades: data
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            grade,
            subject,
            grades,
            subjects
        } = this.state

        return (
            <div>
                <TextField
                    label="Анги"
                    value={grade}
                    onChange={this.setGrade}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                >
                    {grades.map(({ _id, name }) =>
                        <MenuItem key={_id} value={_id}>
                            {name}
                        </MenuItem>
                    )}
                </TextField>
                <TextField
                    label="Хичээлийн төрөл"
                    value={subject}
                    disabled={!subjects.length}
                    onChange={this.setValue('subject')}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                >
                    {subjects.map(({ _id, name }) =>
                        <MenuItem key={_id} value={_id}>
                            {name}
                        </MenuItem>
                    )}
                </TextField>
                <Box
                    my={2}
                    textAlign="right"
                >
                    <Grid
                        spacing={2}
                        justify="space-between"
                        container
                    >
                        <Grid item>
                            {subject &&
                                <Button onClick={this.reset}>
                                    Арилгах
                                </Button>
                            }
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={!subject}
                                onClick={() => this.props.onSubmit(subject)}
                            >
                                Хайх
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }
}

export default withStyles(styles)(LessonFilters)
