import axios from 'axios'
import { store } from './../redux/store'
import { history } from './../routes'

const { dispatch } = store

const counter = () => {
    return store.getState()
        .theme
        .pendingRequests
}

const handleRequest = config => {
    const { token } = store
        .getState()
        .auth

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    if (config && config.progress) {
        dispatch({ type: 'INCREMENT_PENDING_REQUESTS' })
        dispatch({ type: 'START_LOADING' })
    }

    return config
}

const handleResponse = response => {
    if (response.config && response.config.progress) {
        dispatch({ type: 'DECREMENT_PENDING_REQUESTS' })
    }

    if (counter() === 0) {
        dispatch({ type: 'END_LOADING' })
    }

    return response.data
}

const handleError = error => {
    if (error.config && error.config.progress) {
        dispatch({ type: 'DECREMENT_PENDING_REQUESTS' })
    }

    if (counter() === 0) {
        dispatch({ type: 'END_LOADING' })
    }

    if (error.response && error.response.status === 401) {
        dispatch({ type: 'REMOVE_AUTH' })
        history.push('/login')
    }

    if (error.response && error.response.status === 402) {
        history.push('/buy/plan')
    }

    return Promise.reject(error)
}

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`
})

instance.interceptors.request.use(handleRequest)
instance.interceptors.response.use(
    handleResponse,
    handleError
)

export default instance
