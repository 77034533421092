import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    TablePagination
} from '@material-ui/core'
import { App } from './../layouts'
import {
    CardLesson,
    LessonFilters,
    Section
} from './../components'
import axios from './../plugins/axios'

const rowsPerPage = 12

const styles = theme => ({
    //
})

class Lessons extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lessons: [],
            currentPage: 0,
            total: 0
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (page = 1, subject = null) => {
        try {
            const {
                total,
                currentPage,
                data
            } = await axios.get('lessons', {
                progress: true,
                params: {
                    subject,
                    rowsPerPage,
                    page
                }
            })

            this.setState({
                lessons: data,
                currentPage: currentPage - 1,
                total
            }, () => {
                window.scrollTo(0, 0)
            })
        } catch (err) {
            //
        }
    }

    paginate = async (e, page) => {
        this.fetchData(page + 1)
    }

    render() {
        const {
            lessons,
            currentPage,
            total
        } = this.state

        return (
            <App>
                <Section
                    title="Хичээл"
                    description="Багш нарын ажлын ачааллыг хөнгөвчлөх зорилгоор анги, хичээл тус бүрээр ангилагдсан хичээлд ашиглах үзүүлэн, таниулах материалыг файл хэлбэртэй байршууллаа"
                >
                    <Grid
                        spacing={4}
                        container
                    >
                        <Grid
                            xs={12}
                            sm={5}
                            md={4}
                            lg={3}
                            item
                        >
                            <LessonFilters onSubmit={subject => this.fetchData(1, subject)} />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={7}
                            md={8}
                            lg={9}
                            item
                        >
                            <Grid
                                spacing={4}
                                container
                            >
                                {lessons.map(({ _id, name, coverSrc, isFree }) =>
                                    <Grid
                                        key={_id}
                                        xs={12}
                                        md={6}
                                        lg={4}
                                        item
                                    >
                                        <CardLesson
                                            id={_id}
                                            name={name}
                                            imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                            isFree={isFree}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Box mt={4}>
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    count={total}
                                    page={currentPage}
                                    onChangePage={this.paginate}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(Lessons)
