import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { FormatNumber } from './../components'

const styles = theme => ({
    card: {
        textDecoration: 'none'
    },
    cardImage: {
        paddingBottom: '56.25%',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    title: {
        lineHeight: 1.1,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    description: {
        lineHeight: 1.1,
        fontSize: 15,
        color: 'rgba(0, 0, 0, 0.54)'
    }
})

class CardVideoVariant extends Component {
    static defaultProps = {
        views: 0
    }

    render() {
        const {
            id,
            name,
            imageSrc,
            views
        } = this.props
        const {
            card,
            cardImage,
            title,
            description
        } = this.props.classes

        return (
            <div>
                <Link to={`/videos/${id}`}>
                    <div className={card}>
                        <Grid
                            spacing={2}
                            container
                        >
                            <Grid
                                xs={5}
                                item
                            >
                                <div
                                    className={cardImage}
                                    style={{
                                        backgroundImage: `url(https://images.weserv.nl/?url=${imageSrc}&w=150&q=100)`
                                    }}
                                ></div>
                            </Grid>
                            <Grid
                                xs={7}
                                item
                            >
                                <div className={title}>
                                    {name}
                                </div>
                                <div className={description}>
                                    <FormatNumber value={views} /> үзсэн
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Link>
            </div>
        )
    }
}

export default withStyles(styles)(CardVideoVariant)
