import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { Authenticate } from './../layouts'
import {
    Profile as ProfileDetails,
    ProfilePlanStatus,
    Section
} from './../components'

const styles = theme => ({
    //
})

class Profile extends Component {
    render() {
        return (
            <Authenticate>
                <Section title="Миний профайл">
                    <Grid
                        spacing={4}
                        container
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <ProfileDetails />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                        >
                            <ProfilePlanStatus />
                        </Grid>
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

export default withStyles(styles)(Profile)
