import React, { Component } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/styles'
import {
    Container,
    Typography,
    withWidth
} from '@material-ui/core'

const styles = theme => ({
    root: {
        margin: theme.spacing(8, 0)
    },
    content: {
        padding: theme.spacing(8, 0),
        backgroundColor: '#fdf8ce'
    },
    wave: {
        height: 40,
        backgroundSize: 'auto 50px',
        backgroundRepeat: 'repeat-x'
    },
    reverse: {
        transform: 'rotateX(180deg)'
    },
    typography: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginBottom: theme.spacing(8),
        color: theme.palette.primary.main
    }
})

class SectionWaves extends Component {
    render() {
        const {
            title,
            children,
            width
        } = this.props
        const {
            root,
            content,
            wave,
            reverse,
            typography
        } = this.props.classes

        return (
            <div>
                <div className={root}>
                    <div
                        className={classNames(wave, reverse)}
                        style={{
                            backgroundImage: `url('/static/images/pattern-variant.png')`
                        }}
                    ></div>
                    <div className={content}>
                        <Container>
                            <Typography
                                variant={width === 'xs' ? 'h6' : 'h5'}
                                component="h2"
                                className={typography}
                            >
                                {title}
                            </Typography>
                            {children}
                        </Container>
                    </div>
                    <div
                        className={wave}
                        style={{
                            backgroundImage: `url('/static/images/pattern-variant.png')`
                        }}
                    ></div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withWidth()(SectionWaves))
