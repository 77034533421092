import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Snackbar,
    SnackbarContent,
    TextField,
    Typography
} from '@material-ui/core'
import { Close } from 'mdi-material-ui'
import copy from 'copy-to-clipboard'
import QRCode from 'qrcode.react'
import { Authenticate } from './../layouts'
import {
    BaseButton,
    FormatNumber,
    Section
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    snackbar: {
        backgroundColor: theme.palette.error.dark
    }
})

class BuyPlan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: {},
            open: false,
            submitting: false,
            plans: [],
            amount: 0,
            description: '',
            id: '',
            method: '',
            aidConfirmed: false,
            aid: '',
            discountRate: 0,
            qpayQrCode: '',
            qpayUrl: '',
            lendmnQrCode: '',
            lendmnUrl: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('plans', {
                progress: true,
                params: {
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                plans: data
            })
        } catch (err) {
            //
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    copyText = text => () => {
        copy(text)
    }

    useAid = async () => {
        this.setState({
            open: false
        })

        try {
            const options = {
                progress: true
            }

            const {
                aid,
                discountRate
            } = await axios.post('orders/check/aid', { aid: this.state.aid }, options)

            this.setState({
                aidConfirmed: true,
                aid,
                discountRate
            })
        } catch (err) {
            if (err.response && err.response.status === 422) {
                this.setState({
                    open: true
                })
            }
        }
    }

    clearAid = () => {
        this.setState({
            aidConfirmed: false,
            aid: '',
            discountRate: 0
        })
    }

    submit = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const {
            id,
            method,
            aidConfirmed,
            aid
        } = this.state

        try {
            const data = {
                id,
                method
            }

            if (aidConfirmed) {
                Object.assign(data, { aid })
            }

            const options = {
                progress: true
            }

            const { order } = await axios.post('/buy/plan', data, options)
            const {
                amount,
                qpayQrCode,
                qpayUrl,
                lendmnQrCode,
                lendmnUrl,
                golomtInvoiceId
            } = order

            this.setState({
                amount,
                description: order.number,
                qpayQrCode,
                qpayUrl,
                lendmnQrCode,
                lendmnUrl
            })

            if (golomtInvoiceId) {
                switch (method) {
                    case 'golomt':
                        window.location.replace(`https://ecommerce.golomtbank.com/payment/mn/${golomtInvoiceId}`)
                        break;
                    case 'social-pay':
                        window.location.replace(`https://ecommerce.golomtbank.com/socialpay/mn/${golomtInvoiceId}`)
                        break;
                }
            }
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            submitting,
            plans,
            amount,
            description,
            id,
            method,
            aidConfirmed,
            aid,
            discountRate,
            qpayQrCode,
            qpayUrl,
            lendmnQrCode,
            lendmnUrl
        } = this.state
        const { snackbar } = this.props.classes

        const plan = plans.find(({ _id }) => _id === id)

        const qpay = (
            <div>
                <Box
                    mb={3}
                    textAlign="center"
                >
                    <QRCode
                        value={qpayQrCode}
                        bgColor="#fcfff2"
                    />
                </Box>
                <Typography
                    align="center"
                    variant="body2"
                >
                    Та өөрийн банкны аппаа ашиглан дээрхи QR кодыг уншуулж төлбөрөө төлнө үү
                </Typography>
                <Box
                    mt={3}
                    textAlign="center"
                >
                    <BaseButton href={qpayUrl}>
                        Апп нээх
                    </BaseButton>
                </Box>
            </div>
        )

        const lendmn = (
            <div>
                <Box
                    mb={3}
                    textAlign="center"
                >
                    <QRCode
                        value={lendmnQrCode}
                        bgColor="#fcfff2"
                    />
                </Box>
                <Typography
                    align="center"
                    variant="body2"
                >
                    Та lendmn апп ашиглан дээрхи QR кодыг уншуулж төлбөрөө төлнө үү
                </Typography>
                <Box
                    mt={3}
                    textAlign="center"
                >
                    <BaseButton href={lendmnUrl}>
                        Апп нээх
                    </BaseButton>
                </Box>
            </div>
        )

        const bankAccount = (
            <List>
                <ListItem divider>
                    <ListItemText
                        primary="Хаан банк"
                        secondary="5175025418"
                        primaryTypographyProps={{
                            variant: 'body2',
                            color: 'textSecondary'
                        }}
                        secondaryTypographyProps={{
                            variant: 'body1',
                            color: 'textPrimary'
                        }}
                    />
                    <ListItemSecondaryAction>
                        <Button
                            color="primary"
                            onClick={this.copyText('5175025418')}
                        >
                            Хуулах
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary="Дансны нэр"
                        secondary="Марчаахай ХХК"
                        primaryTypographyProps={{
                            variant: 'body2',
                            color: 'textSecondary'
                        }}
                        secondaryTypographyProps={{
                            variant: 'body1',
                            color: 'textPrimary'
                        }}
                    />
                    <ListItemSecondaryAction>
                        <Button
                            color="primary"
                            onClick={this.copyText('Марчаахай ХХК')}
                        >
                            Хуулах
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary="Төлөх дүн"
                        secondary={
                            <FormatNumber
                                value={amount}
                                suffix=" MNT"
                            />
                        }
                        primaryTypographyProps={{
                            variant: 'body2',
                            color: 'textSecondary'
                        }}
                        secondaryTypographyProps={{
                            variant: 'body1',
                            color: 'textPrimary'
                        }}
                    />
                    <ListItemSecondaryAction>
                        <Button
                            color="primary"
                            onClick={this.copyText(amount)}
                        >
                            Хуулах
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Гүйлгээний утга"
                        secondary={description}
                        primaryTypographyProps={{
                            variant: 'body2',
                            color: 'textSecondary'
                        }}
                        secondaryTypographyProps={{
                            variant: 'body1',
                            color: 'textPrimary'
                        }}
                    />
                    <ListItemSecondaryAction>
                        <Button
                            color="primary"
                            onClick={this.copyText(description)}
                        >
                            Хуулах
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        )

        const form = (
            <form
                onSubmit={this.submit}
                autoComplete="off"
                noValidate
            >
                <TextField
                    label="Багц"
                    value={id}
                    error={!!errors.id}
                    helperText={errors.id}
                    onChange={this.handleChange('id')}
                    variant="outlined"
                    margin="normal"
                    children={plans.map(({ _id, name, period }, index) =>
                        <MenuItem
                            value={_id}
                            key={index}
                        >
                            {name}
                        </MenuItem>
                    )}
                    fullWidth
                    select
                />
                <TextField
                    label="Төлбөрийн хэрэгсэл"
                    value={method}
                    error={!!errors.method}
                    helperText={errors.method}
                    onChange={this.handleChange('method')}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                >
                    <MenuItem value="bank-account">
                        Дансаар
                    </MenuItem>
                    <MenuItem value="golomt">
                        Картаар
                    </MenuItem>
                    <MenuItem value="qpay">
                        QR кодоор
                    </MenuItem>
                    <MenuItem value="lendmn">
                        SuperUp хэтэвчээр
                    </MenuItem>
                    <MenuItem value="social-pay">
                        Social Pay
                    </MenuItem>
                </TextField>
                {aidConfirmed
                    ?
                    <TextField
                        label="Купон код"
                        value={`${aid} (${discountRate}% хямдрал)`}
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={this.clearAid}
                                    >
                                        <Close />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        disabled
                        fullWidth
                    />
                    :
                    <TextField
                        label="Купон код"
                        value={aid}
                        onChange={this.handleChange('aid')}
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={this.useAid}
                                        disabled={!aid}
                                    >
                                        Ашиглах
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                    />
                }
                {plan &&
                    <TextField
                        label="Төлөх дүн"
                        value={(1 - discountRate / 100) * plan.price}
                        variant="outlined"
                        margin="normal"
                        disabled
                        fullWidth
                    />
                }
                <Box
                    mt={2}
                    textAlign="right"
                >
                    <Grid
                        spacing={4}
                        alignItems="center"
                        justify="space-between"
                        container
                    >
                        <Grid
                            alignItems="center"
                            justify="space-between"
                            container
                            item
                        >
                            <Grid item>
                                <BaseButton
                                    type="submit"
                                    disabled={submitting}
                                >
                                    Авах
                                </BaseButton>
                            </Grid>
                            <Grid item>
                                <Link
                                    component={RouterLink}
                                    to="/use/coupon"
                                >
                                    Бэлгийн карт ашиглах
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        )

        const payment = () => {
            switch (method) {
                case 'bank-account':
                    return bankAccount
                case 'qpay':
                    return qpay
                case 'lendmn':
                    return lendmn
                default:
                    return form
            }
        }

        return (
            <Authenticate>
                <Snackbar
                    open={open}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <SnackbarContent
                        className={snackbar}
                        message={
                            <span>
                                Таны оруулсан купон код буруу байна.
                            </span>
                        }
                        action={[
                            <IconButton
                                color="inherit"
                                onClick={this.closeSnackbar}
                            >
                                <Close />
                            </IconButton>
                        ]}
                    />
                </Snackbar>
                <Section title="Багц авах">
                    <Grid
                        justify="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={6}
                            lg={5}
                            item
                        >
                            <Box mb={3}>
                                <Typography
                                    align="center"
                                    variant="subtitle2"
                                >
                                    Та багц худалдан авч "Үндсэн хэрэглэгч" болсноор бүх контентыг үзэх эрхтэй болно.
                                </Typography>
                            </Box>
                            {description ? payment() : form}
                        </Grid>
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

export default withStyles(styles)(BuyPlan)
