import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/styles'
import { ThemeContext } from './../theme/context'
import {
    MainBar,
    MainDrawer,
    MainFooter,
    MainLinearLoader,
    ScrollToTop
} from './../components'

const styles = theme => ({
    spacer: {
        display: 'block',
        height: 64
    }
})

class App extends Component {
    static defaultProps = {
        title: ''
    }

    constructor(props) {
        super(props)

        this.state = {
            drawer: false,
            toggleDrawer: this.toggleDrawer
        }
    }

    toggleDrawer = () => {
        this.setState(state => ({
            drawer: !state.drawer
        }))
    }

    render() {
        const {
            children,
            title
        } = this.props
        const { spacer } = this.props.classes

        return (
            <ThemeContext.Provider value={this.state}>
                {title &&
                    <Helmet>
                        <title>
                            {title}
                        </title>
                    </Helmet>
                }
                <ScrollToTop>
                    <main>
                        <MainBar />
                        <div className={spacer}></div>
                        <MainLinearLoader />
                        <MainDrawer />
                        {children}
                        <MainFooter />
                    </main>
                </ScrollToTop>
            </ThemeContext.Provider>
        )
    }
}

export default withStyles(styles)(App)
