import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'

const styles = theme => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999
    }
})

class MainLinearLoader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //
        }
    }

    render() {
        const { loading } = this.props
        const { container } = this.props.classes

        if (!loading) {
            return null
        }

        return (
            <div className={container}>
                <LinearProgress />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.theme.loading
})

export default connect(mapStateToProps)(withStyles(styles)(MainLinearLoader))
