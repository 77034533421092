import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Slider from 'react-slick'
import { Partner } from './../components'

const styles = theme => ({
    container: {
        maxWidth: 1000,
        margin: '0 auto'
    }
})

const items = [
    '/static/images/partner-1.png',
    '/static/images/partner-2.png',
    '/static/images/partner-3.png',
    '/static/images/partner-4.png',
    '/static/images/partner-5.png'
]

class HomePartners extends Component {
    render() {
        const {
            container
        } = this.props.classes
        const settings = {
            dots: false,
            arrows: false,
            autoplay: true,
            swipeToSlide: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        }

        return (
            <div className={container}>
                <Slider {...settings}>
                    {items.map((item, index) => (
                        <div key={index}>
                            <Partner image={item} />
                        </div>
                    ))}
                </Slider>
            </div>
        )
    }
}

export default withStyles(styles)(HomePartners)
