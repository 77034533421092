import React, { Component } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Divider,
    Grid,
    Hidden,
    Link,
    TextField,
    Typography
} from '@material-ui/core'
import { Facebook } from 'mdi-material-ui'
import axios from './../plugins/axios'
import { setAuth } from './../redux/auth/actions'
import {
    BaseButton,
    BasePasswordField,
    MainLinearLoader
} from './../components'

const styles = theme => ({
    image: {
        display: 'block',
        minHeight: '100vh',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    facebookButton: {
        backgroundColor: '#1877f2'
    }
})

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            errors: {},
            submitting: false
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    login = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const {
            email,
            password
        } = this.state

        const { setAuth, history } = this.props

        try {
            const {
                user,
                token
            } = await axios.post('login', {
                username: email,
                password
            })

            setAuth(user, token)
            history.push('/')
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    loginFacebook = async ({ accessToken }) => {
        try {
            const {
                user,
                token
            } = await axios.post('login/facebook', { accessToken }, {
                progress: true
            })

            this.props.setAuth(user, token)
            this.props.history.push('/')
        } catch (err) {
            //
        }
    }

    render() {
        const {
            email,
            password,
            errors,
            submitting
        } = this.state
        const {
            image,
            icon,
            facebookButton
        } = this.props.classes

        return (
            <div>
                <MainLinearLoader />
                <Grid
                    alignItems="center"
                    justify="center"
                    container
                >
                    <Grid
                        xs={12}
                        sm={8}
                        md={6}
                        lg={5}
                        xl={4}
                        item
                    >
                        <Box p={4}>
                            <form
                                onSubmit={this.login}
                                autoComplete="off"
                                noValidate
                            >
                                <Box
                                    mb={3}
                                    textAlign="center"
                                >
                                    <RouterLink to="/">
                                        <img
                                            height="130"
                                            src="/static/images/user.png"
                                            alt="marchaakhai"
                                        />
                                    </RouterLink>
                                </Box>
                                <Box
                                    mb={4}
                                    textAlign="center"
                                >
                                    <Link
                                        component={RouterLink}
                                        to="/"
                                    >
                                        Нүүр хуудас руу буцах
                                    </Link>
                                </Box>
                                <Box mb={4}>
                                    <Typography
                                        align="center"
                                        variant="subtitle2"
                                    >
                                        Та хэрэглэгчээр бүртгүүлж "Үндсэн хэрэглэгч" болсноор бүх контентыг үзэх эрхтэй болно.
                                    </Typography>
                                </Box>
                                <Box
                                    mb={6}
                                    textAlign="center"
                                >
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                        fields="id,email"
                                        callback={this.loginFacebook}
                                        redirectUri="https://marchaakhai.mn/login"
                                        render={props => (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={facebookButton}
                                                onClick={props.onClick}
                                            >
                                                <Facebook className={icon} />
                                                Continue with Facebook
                                            </Button>
                                        )}
                                        disableMobileRedirect
                                        autoLoad
                                    />
                                </Box>
                                <Box mb={4}>
                                    <Divider />
                                </Box>
                                <TextField
                                    label="И-мэйл хаяг эсвэл утас"
                                    value={email}
                                    error={!!errors.username}
                                    helperText={errors.username}
                                    onChange={this.handleChange('email')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                />
                                <BasePasswordField
                                    label="Нууц үг"
                                    value={password}
                                    error={!!errors.password}
                                    onChange={this.handleChange('password')}
                                />
                                <Box mt={2}>
                                    <Grid
                                        alignItems="center"
                                        justify="space-between"
                                        container
                                    >
                                        <Grid item>
                                            <BaseButton
                                                type="submit"
                                                disabled={submitting}
                                            >
                                                Нэвтрэх
                                            </BaseButton>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                component={RouterLink}
                                                to="/register"
                                            >
                                                Бүртгүүлэх
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    my={8}
                                    textAlign="center"
                                >
                                    <Link
                                        component={RouterLink}
                                        to="/forgot/password"
                                    >
                                        Нууц үгээ мартсан уу?
                                    </Link>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        <Grid
                            lg={7}
                            xl={8}
                            item
                        >
                            <div
                                className={image}
                                style={{
                                    backgroundImage: 'url(/static/images/login-bg.png)'
                                }}
                            ></div>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        )
    }
}

const mapDispatchToProps = {
    setAuth
}

const component = withStyles(styles)(Login)

export default connect(
    null,
    mapDispatchToProps
)(component)
