import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    VerticalTimeline,
    VerticalTimelineElement
} from 'react-vertical-timeline-component'
import { Typography } from '@material-ui/core'
import { Seal } from 'mdi-material-ui'
import { FormatDate } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

const iconStyle = {
    backgroundColor: '#ffc700',
    color: '#fff'
}

class AboutAwards extends Component {
    constructor(props) {
        super(props)

        this.state = {
            awards: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('awards', {
                progress: true,
                params: {
                    sortBy: 'date',
                    sortOrder: 'desc',
                    rowsPerPage: 0
                }
            })

            this.setState({
                awards: data
            })
        } catch (err) {
            //
        }
    }

    render() {
        return (
            <div>
                <VerticalTimeline>
                    {this.state.awards.map(({ _id, date, description }) =>
                        <VerticalTimelineElement
                            key={_id}
                            date={<FormatDate date={date} />}
                            iconStyle={iconStyle}
                            icon={<Seal />}
                        >
                            <Typography variant="subtitle2">
                                {description}
                            </Typography>
                        </VerticalTimelineElement>
                    )}
                </VerticalTimeline>
            </div>
        )
    }
}

export default withStyles(styles)(AboutAwards)
