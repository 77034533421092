import React, { Component } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'

const effect = theme => ({
    top: 3,
    boxShadow: '0 5px 0 #9e032f',
    backgroundColor: theme.palette.primary.main
})

const styles = theme => ({
    root: {
        position: 'relative',
        top: 0,
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1, 4),
        boxShadow: '0 8px 0 #9e032f',
        borderRadius: 10,
        transition: 'top 0.2s, box-shadow 0.2s',
        '&:hover': effect(theme),
        '&:focus': effect(theme)
    }
})

class BaseButton extends Component {
    render() {
        const { root } = this.props.classes
        const {
            children,
            className,
            ...props
        } = this.props

        return (
            <Button
                className={classNames(root, className)}
                variant="contained"
                color="primary"
                {...props}
            >
                {children}
            </Button>
        )
    }
}

export default withStyles(styles)(BaseButton)
