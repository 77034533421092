import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    TablePagination,
    Grid
} from '@material-ui/core'
import { App } from './../layouts'
import {
    Section,
    CardPost
} from './../components'
import axios from './../plugins/axios'

const rowsPerPage = 9

const styles = theme => ({
    //
})

class Posts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            posts: [],
            currentPage: 0,
            total: 0
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    fetchData = async (page = 1) => {
        try {
            const {
                total,
                currentPage,
                data
            } = await axios.get('posts', {
                progress: true,
                params: {
                    rowsPerPage,
                    page
                }
            })

            this.setState({
                posts: data,
                currentPage: currentPage - 1,
                total
            }, () => {
                window.scrollTo(0, 0)
            })
        } catch (err) {
            //
        }
    }

    paginate = async (e, page) => {
        this.fetchData(page + 1)
    }

    render() {
        const {
            posts,
            currentPage,
            total
        } = this.state

        return (
            <App>
                <Section title="Зөвлөгөө">
                    <Grid
                        spacing={4}
                        container
                    >
                        {posts.map(({ _id, title, coverSrc }) =>
                            <Grid
                                key={_id}
                                xs={12}
                                sm={4}
                                item
                            >
                                <CardPost
                                    id={_id}
                                    title={title}
                                    imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Box mt={4}>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[]}
                            rowsPerPage={rowsPerPage}
                            count={total}
                            page={currentPage}
                            onChangePage={this.paginate}
                        />
                    </Box>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(Posts)
