import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Avatar
} from '@material-ui/core'
import { Camera } from 'mdi-material-ui'
import { setUser } from './../redux/auth/actions'
import axios from './../plugins/axios'

const styles = theme => ({
    root: {
        display: 'inline-block',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    avatar: {
        width: 100,
        height: 100
    },
    uploadButton: {
        position: 'absolute',
        padding: '4px 0',
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: '#fff'
    },
    input: {
        display: 'none'
    }
})

class UserAvatar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploading: false,
            errors: {}
        }
    }

    uploadFile = async e => {
        try {
            this.setState({
                uploading: false,
                errors: {}
            })

            const formData = new FormData()
            formData.append('file', e.target.files[0])

            const config = {
                progress: true,
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const { setUser } = this.props
            const { user } = await axios.post('users/change/avatar', formData, config)

            setUser(user)

            this.setState({
                uploading: false
            })
        } catch (err) {
            // Handle error
        }
    }

    render() {
        const { uploading } = this.state
        const { file } = this.props
        const {
            root,
            avatar,
            uploadButton,
            input
        } = this.props.classes

        return (
            <div>
                <label>
                    <input
                        className={input}
                        onChange={this.uploadFile}
                        disabled={uploading}
                        type="file"
                    />
                    <Box
                        className={root}
                        component="span"
                    >
                        <Avatar
                            alt="avatar"
                            src={file ? `${process.env.REACT_APP_API_URL}/${file}` : '/static/images/placeholder.png'}
                            className={avatar}
                        />
                        <div className={uploadButton}>
                            <Camera fontSize="small" />
                        </div>
                    </Box>
                </label>
            </div>
        )
    }
}

const mapDispatchToProps = {
    setUser
}

const component = withStyles(styles)(UserAvatar)

export default connect(
    null,
    mapDispatchToProps
)(component)
