import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Checkbox,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    ListItemText,
    MenuItem,
    TextField,
    Typography,
    withWidth
} from '@material-ui/core'
import { Magnify } from 'mdi-material-ui'
import axios from './../plugins/axios'
import { debounce } from './../utils/helpers'

const styles = theme => ({
    formControl: {
        marginBottom: 48
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
})

class Filters extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: props.search,
            selections: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })

        if (name === 'search') {
            debounce(() => {
                this.props.onChangeSearch(this.state.search)
            })
        }
    }

    fetchData = async () => {
        try {
            const { data } = await axios.get('categories', {
                progress: true,
                params: {
                    sortBy: 'name',
                    sortOrder: 'asc',
                }
            })

            this.setState({
                selections: data
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            width,
            categories,
            onChangeCategories
        } = this.props
        const {
            formControl,
            icon
        } = this.props.classes
        const {
            search,
            selections
        } = this.state

        return (
            <div>
                <Grid
                    justify="center"
                    container
                >
                    <Grid
                        xs={12}
                        sm={6}
                        lg={4}
                        item
                    >
                        <div className={formControl}>
                            <TextField
                                label="Хайх"
                                value={search}
                                onChange={this.handleChange('search')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Magnify className={icon} />
                                        </InputAdornment>
                                    )
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    alignItems="center"
                    justify="space-between"
                    wrap="nowrap"
                    spacing={2}
                    container
                >
                    {width !== 'xs' &&
                        <Grid item>
                            <Typography variant="subtitle1">
                                Шүүлтүүр
                            </Typography>
                        </Grid>
                    }
                    {width !== 'xs' &&
                        <Grid item xs>
                            <Divider variant="middle" />
                        </Grid>
                    }
                    <Grid
                        xs={width === 'xs'}
                        xs={12}
                        sm={5}
                        md={3}
                        lg={2}
                        item
                    >
                        <TextField
                            label="Ангилал"
                            value={categories}
                            onChange={e => onChangeCategories(e.target.value)}
                            variant="outlined"
                            SelectProps={{
                                multiple: true,
                                renderValue: value => selections.filter(({ _id }) => value.includes(_id)).map(({ name }) => name).join(', ')
                            }}
                            fullWidth
                            select
                        >
                            {selections.map(({ _id, name }, index) =>
                                <MenuItem
                                    key={index}
                                    value={_id}
                                >
                                    <Checkbox checked={categories.indexOf(_id) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default withStyles(styles)(withWidth()(Filters))
