import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    TextField
} from '@material-ui/core'
import { setUser } from './../redux/auth/actions'
import { Authenticate } from './../layouts'
import {
    BaseButton,
    Section
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class UpdateMe extends Component {
    constructor(props) {
        super(props)

        const {
            name
        } = props.user

        this.state = {
            name,
            errors: {},
            submitting: false
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    submit = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const {
            name
        } = this.state

        const { setUser, history } = this.props

        try {
            const { user } = await axios.post('/users/me', {
                name
            })

            setUser(user)
            history.push('/profile')
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            name,
            errors,
            submitting
        } = this.state

        return (
            <Authenticate>
                <Section title="Профайл шинэчлэх">
                    <Grid
                        justify="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={6}
                            lg={5}
                            item
                        >
                            <form
                                onSubmit={this.submit}
                                autoComplete="off"
                                noValidate
                            >
                                <TextField
                                    label="Нэр"
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    value={name}
                                    onChange={this.handleChange('name')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <Box
                                    mt={2}
                                    textAlign="right"
                                >
                                    <BaseButton
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Хадгалах
                                    </BaseButton>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

const mapDispatchToProps = {
    setUser
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UpdateMe))
