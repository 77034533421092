import React, { Component } from 'react'
import queryString from 'query-string'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Grid,
    Hidden,
    TextField,
    Typography,
    Snackbar,
    SnackbarContent
} from '@material-ui/core'
import axios from './../plugins/axios'
import {
    BaseButton,
    BasePasswordField
} from './../components'

const styles = theme => ({
    snackbar: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 400
        }
    },
    image: {
        display: 'block',
        minHeight: '100vh',
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom'
    }
})

class VerifyPhone extends Component {
    constructor(props) {
        super(props)

        this.state = {
            code: '',
            password: '',
            passwordConfirm: '',
            errors: {},
            submitting: false,
            open: false
        }
    }

    reset = () => {
        this.setState({
            code: '',
            password: '',
            passwordConfirm: '',
            errors: {},
            submitting: false
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    register = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const { search } = this.props.location
        const {
            countryCode,
            phone
        } = queryString.parse(search)

        const {
            code,
            password,
            passwordConfirm
        } = this.state

        const data = {
            code,
            countryCode,
            phone,
            password,
            passwordConfirm
        }

        try {
            await axios.post('register/phone/number', data, {
                progress: true
            })

            this.reset()
            this.setState({
                open: true
            })
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            code,
            password,
            passwordConfirm,
            errors,
            submitting,
            open
        } = this.state
        const {
            snackbar,
            image
        } = this.props.classes

        return (
            <div>
                <Snackbar
                    open={open}
                    onClose={this.closeSnackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <SnackbarContent
                        className={snackbar}
                        message={
                            <span>
                                Та амжилттай бүртгүүллээ.
                        </span>
                        }
                        action={[
                            <Button
                                color="primary"
                                size="small"
                                component={RouterLink}
                                to="/login"
                            >
                                Нэвтрэх
                            </Button>
                        ]}
                    />
                </Snackbar>
                <Grid
                    alignItems="center"
                    justify="center"
                    container
                >
                    <Grid
                        xs={12}
                        sm={8}
                        md={6}
                        lg={5}
                        xl={4}
                        item
                    >
                        <Box p={4}>
                            <form
                                onSubmit={this.register}
                                autoComplete="off"
                                noValidate
                            >
                                <Box
                                    mb={4}
                                    textAlign="center"
                                >
                                    <RouterLink to="/">
                                        <img
                                            height="130"
                                            src="/static/images/user.png"
                                            alt="marchaakhai"
                                        />
                                    </RouterLink>
                                </Box>
                                <Box mb={4}>
                                    <Typography
                                        align="center"
                                        variant="subtitle2"
                                    >
                                        Таны оруулсан утасны дугаар луу баталгаажуулах код илгээлээ.
                                    </Typography>
                                </Box>
                                <TextField
                                    label="Код"
                                    value={code}
                                    error={!!errors.code}
                                    helperText={errors.code}
                                    onChange={this.handleChange('code')}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                />
                                <BasePasswordField
                                    label="Шинэ нууц үг"
                                    value={password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={this.handleChange('password')}
                                />
                                <TextField
                                    label="Шинэ нууц үг дахин оруулна уу"
                                    value={passwordConfirm}
                                    onChange={this.handleChange('passwordConfirm')}
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <Box
                                    mt={2}
                                    textAlign="right"
                                >
                                    <BaseButton
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Бүртгүүлэх
                                    </BaseButton>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        <Grid
                            lg={7}
                            xl={8}
                            item
                        >
                            <div
                                className={image}
                                style={{
                                    backgroundImage: 'url(/static/images/login-bg.png)'
                                }}
                            ></div>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(VerifyPhone)
