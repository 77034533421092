import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { App } from './../layouts'
import {
    AboutAwards,
    AboutIntro,
    Section,
    SectionWaves
} from './../components'

const styles = theme => ({
    //
})

class About extends Component {
    render() {
        return (
            <App>
                <Section title="Танилцуулга">
                    <AboutIntro />
                </Section>
                <SectionWaves title="Шагнал">
                    <AboutAwards />
                </SectionWaves>
            </App>
        )
    }
}

export default withStyles(styles)(About)
