import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { App } from './../layouts'
import { Section } from './../components'

const styles = theme => ({
    content: {
        fontSize: 16,
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '30px'
    }
})

class PrivacyPolicy extends Component {
    render() {
        const {
            content
        } = this.props.classes

        return (
            <App>
                <Section title="Нууцлалын бодлого">
                    <div className={content}>
                        <p>Энэхүү Нууцлалын Бодлого нь "Марчаахай" ХХК (цаашид "Компани" гэж нэрлэх) хэрэглэгчийн хувийн мэдээллийг хэрхэн бүртгэн авах, ашиглах, дамжуулах, хадгалахыг зохицуулсан баримт бичиг юм.</p>
                        <p>Компани нь www.marchaakhai.mn вэб болон мобайл аппликэйшн (хамтад нь "Үйлчилгээ" гэж нэрлэх)-д бүртгүүлсэн бүх хэрэглэгчдийн хувийн нууцлалыг энэхүү баримт бичигт зааснаар хадгалж, хамгаална.</p>
                        <p>Компани нь энэхүү нууцлалын бодлогод тусгаснаас өөрөөр болон Монгол улсын хуульд зааснаас бусад ямар ч тохиолдолд хэрэглэгчийн аливаа мэдээллийг гуравдагч этгээдэд дамжуулахгүй. Үйлчилгээг авч байгаа бол хэрэглэгчийн уг нууцлалын бодлогыг зөвшөөрсөн гэж үзнэ.</p>
                    </div>
                    <Typography variant="h6">
                        НЭГ. БҮТЭЭГДЭХҮҮНИЙ ТУХАЙ
                    </Typography>
                    <div className={content}>
                        <p>Марчаахай веб болон аппликейшнаар дамжуулан 2-8 насны хүүхдүүдэд зориулсан танин мэдэхүйн дараах төрлийн хүүхдийн уран бүтээлийг үзнэ.</p>
                        <ol>
                            <li>Хүүхдийн дуу</li>
                            <li>Үлгэр</li>
                            <li>Шүлэг</li>
                            <li>Танин мэдэхүйн зохиол</li>
                            <li>Ардын аман зохиол</li>
                            <li>Оньсого, таавар</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ХОЁР. ХЭРЭГЛЭГЧИЙН БҮРТГЭЛИЙН ҮЕД АВАХ МЭДЭЭЛЭЛ
                    </Typography>
                    <div className={content}>
                        <p>Веб сайт болон мобайл аппликэйшныг 14 болон түүнээс дээш насны хэрэглэгчид захиалга өгөх эрхтэй. Түүнээс бага насны хэрэглэгч хуурамч захиалга өгсөн тохиолдолд эцэг эх, асран хамгаалагч нь хариуцлагыг бүрэн хүлээнэ. Хувийн мэдээллийг үүсгэх, мэдээлэл боловсруулах, ашиглахдаа хувь хүний нууцыг хамгаалах тухай хууль болон холбогдох бусад хууль тогтоомжийг баримтална.</p>
                        <p>Хэрэглэгчийн бүртгэл дараах байдлаар үүснэ:</p>
                        <ol>
                            <li>Фэйсбүүкээр нэвтрэх</li>
                            <li>Имэйл хаяг</li>
                            <li>Утасны дугаар</li>
                            <li>Нэвтрэх нэр, нууц үг</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ГУРАВ. МЭДЭЭЛЭЛ АШИГЛАХ
                    </Typography>
                    <div className={content}>
                        <p>Хэрэглэгчийн мэдээллийг дараах байдлаар ашиглаж болно. Үүнд:</p>
                        <ol>
                            <li>Маргаан шийдвэрлэх, хураамж төлбөр авах, техникийн асуудлуудыг шийдэхэд</li>
                            <li>Хууль бус үйл ажиллагаанаас урьдчилан сэргийлэх, Үйлчилгээний нөхцөлийг мөрдүүлэхэд</li>
                            <li>Компанийн өмнөөс мессеж, имэйлээр маркетингийн болон сурталчилгааны санал явуулахад</li>
                            <li>Хэрэглэгчийн хэрэгцээ, санал хүсэлтийг хүлээн авах, илүү сайн үйлчилгээ үзүүлэхийн тулд хэрэглэгчийн хувийн мэдээллийг бусдаас олж авсан мэдээлэлтэй холбох буюу нэгтгэхэд</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ДӨРӨВ. МЭДЭЭЛЭЛ БОЛОВСРУУЛАХ, ДАМЖУУЛАХ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Төрийн эрх бүхий байгууллагын шийдвэрийг биелүүлэх, хуулийн дагуу мөрдөн шалгалт хийхэд оролцох зэрэг зорилгоор шилжүүлэх</li>
                            <li>Компани хууль, эрх зүйн үүргээ биелүүлэхийн тулд хэрэглэгчийн мэдээллийг задлах,  хуваалцах үүрэгтэй</li>
                            <li>Компани нь хэрэглэгчээ таних, мөнгө угаах, терроризмыг санхүүжүүлэхийн эсрэг тэмцэх үйл ажиллагаанд хяналт тавихад дэмжин ажилладаг гуравдагч этгээдэд шилжүүлж болно</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ТАВ. АЮУЛГҮЙ БАЙДАЛ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Бид хэрэглэгчийн хувийн мэдээллийн нууцлал, аюулгүй байдлыг хамгаалахын тулд Монгол Улсын хууль тогтоомжийг баримтлан ажилладаг. </li>
                            <li>Хэрэглэгчийн мэдээллийн аюулгүй байдлыг хангах зорилгоор Хэрэглэгчийн мэдээлэлд зөвшөөрөлгүй эсхүл санаандгүй халдах, устгах, өөрчлөх, болоклох, хуулбарлах, гуравдагч этгээдэд дамжуулах зэрэг хууль бус үйл ажиллагаанаас хамгаалах шаардлагатай, хууль ёсны, байгууллагын болон техникийн арга хэмжээнүүдийг авч ажиллана.</li>
                            <li>Хэрэглэгчийн хувийн мэдээлэлд хандаж буй холбогдох ажилтнууд нь хувийн мэдээллийг задруулахгүй байх үүрэгтэй.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ЗУРГАА. МЭДЭЭЛЭЛД НЭВТРЭХ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Хэрэглэгч өөрийн хувийн мэдээллийг биднээс авах эрхтэй. Түүнчлэн хэрэглэгч компанитай хамтран ажиллаж буй гуравдагч этгээдээс үйлчилгээ авч буй тохиолдолд өөрийн мэдээллийг харах, нэвтрэх зөвшөөрлийг зөвхөн хэрэглэгч өгөх эрхтэй.</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ДОЛОО. ХУВИЙН МЭДЭЭЛЭЛ АШИГЛАХ, БОЛОВСРУУЛАХ ҮЙЛ АЖИЛЛАГААГ ДУУСГАВАР БОЛГОХ
                    </Typography>
                    <div className={content}>
                        <p>Дараах нөхцөлүүдэд бид таны хувийн мэдээллийг боловсруулахыг зогсоох болно.</p>
                        <ol>
                            <li>Төрийн эрх бүхий байгууллагын шийдвэрээр мэдээлэл боловсруулах, ашиглахыг хориглосон</li>
                            <li>Гэнэтийн болон давагдашгүй хүчин зүйлийн нөлөөллөөр мэдээллийг ашиглах боломжгүй нөхцөл үүссэн</li>
                            <li>Марчаахай веб болон аппликейны үйлчилгээ эрхлэгч нь үйл ажиллагаагаа зогсоосон</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        НАЙМ. ХЭРЭГЛЭГЧ МЭДЭЭЛЛЭЭ УДИРДАХ
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Хэрэглэгч хувийн мэдээлэл болон нууц үгээ хэдийд ч өөрчлөх боломжтой</li>
                            <li>Хэрэглэгч хувийн мэдээллээ устгуулах эрхтэй</li>
                            <li>Хэрэв хэрэглэгч өөрийн бүртгэл, мэдээллээ устгахыг хүссэн тохиолдолд info@marchaakhai.mn цахим шуудангийн хаягт хүсэлтээ илгээснээр бид ажлын 1-3 хоногт системээс хэрэглэгчийн бүртгэл, мэдээллийг устгах болно</li>
                        </ol>
                    </div>
                    <Typography variant="h6">
                        ЕС. ХОЛБОО БАРИХ
                    </Typography>
                    <div className={content}>
                        <p>Хувийн мэдээлэл, түүний аюулгүй байлтай холбоотой тодруулах зүйл байвал info@marchaakhai.mn цахим хаяг болон (+976) 72725555 дугаарт утсаар харилцах, холбоо барих боломжтой.</p>
                    </div>
                    <Typography variant="h6">
                        АРАВ. ХАРИУЦЛАГА
                    </Typography>
                    <div className={content}>
                        <ol>
                            <li>Марчаахай веб болон аппликейшн эрхлэгч нь Хэрэглэгчийн хувийн мэдээллийг хууль бусаар задруулсан, боловсруулсан, дамжуулсан, ашигласан бол Монгол Улсын Эрүүгийн эсхүл Зөрчлийн тухай хуулийн дагуу хариуцлага хүлээнэ.</li>
                            <li>Хэрэглэгч хувийн мэдээллээ үнэн зөв оруулах үүргээ биелүүлээгүйтэй холбоотой хариуцлагыг өөрөө хүлээнэ.</li>
                        </ol>
                    </div>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(PrivacyPolicy)
