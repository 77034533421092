import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    TextField
} from '@material-ui/core'
import { Authenticate } from './../layouts'
import {
    BaseButton,
    BasePasswordField,
    Section
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class ChangePassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            password: '',
            passwordConfirm: '',
            errors: {},
            submitting: false
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    submit = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            submitting: true
        })

        const {
            password,
            passwordConfirm
        } = this.state

        const { history } = this.props

        try {
            await axios.post('/users/change/password', {
                password,
                passwordConfirm
            })

            history.push('/profile')
        } catch (err) {
            if (err.response && err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    submitting: false
                })
            }
        }
    }

    render() {
        const {
            password,
            passwordConfirm,
            errors,
            submitting
        } = this.state

        return (
            <Authenticate>
                <Section title="Нууц үг солих">
                    <Grid
                        justify="center"
                        container
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={6}
                            lg={5}
                            item
                        >
                            <form
                                onSubmit={this.submit}
                                autoComplete="off"
                                noValidate
                            >
                                <BasePasswordField
                                    label="Шинэ нууц үг"
                                    value={password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={this.handleChange('password')}
                                />
                                <TextField
                                    label="Шинэ нууц үг дахин оруулна уу"
                                    value={passwordConfirm}
                                    onChange={this.handleChange('passwordConfirm')}
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <Box
                                    mt={2}
                                    textAlign="right"
                                >
                                    <BaseButton
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        Хадгалах
                                    </BaseButton>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

export default withStyles(styles)(ChangePassword)
