import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Link,
    generatePath
} from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Chip,
    Container,
    Divider,
    Grid
} from '@material-ui/core'
import { App } from './../layouts'
import {
    CardVideoVariant,
    Player
} from './../components'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

const styles = theme => ({
    nextUp: {
        fontSize: 20,
        lineHeight: 1
    },
    chipContainer: {
        margin: '0 -4px'
    },
    chip: {
        marginLeft: 4,
        marginRight: 4
    }
})

class Video extends Component {
    constructor(props) {
        super(props)

        this.state = {
            suggests: [],
            name: '',
            filePath: '',
            views: 0,
            tags: []
        }
    }

    componentDidMount() {
        this.fetchSuggests()
        this.fetchData()
    }

    fetchSuggests = async () => {
        try {
            const { params } = this.props.match
            const { id } = params

            const { videos } = await axios.get(pathJoin('suggest', 'videos', id), { progress: true })

            this.setState({
                suggests: videos
            })
        } catch (err) {
            //
        }
    }

    fetchData = async () => {
        try {
            const { params } = this.props.match
            const { id } = params

            const { video } = await axios.get(pathJoin('videos', id), { progress: true })
            const {
                name,
                publicFilePath,
                views,
                tags
            } = video

            this.setState({
                name,
                filePath: publicFilePath,
                views,
                tags
            })
        } catch (err) {
            //
        }
    }

    goToNextVideo = () => {
        const {
            suggests
        } = this.state

        if (suggests.length) {
            const { _id } = suggests[0]

            this.props.history.push(generatePath('/videos/:id', {
                id: _id
            }))
        }
    }

    render() {
        const {
            name,
            filePath,
            views,
            tags,
            suggests
        } = this.state
        const { token } = this.props
        const { params } = this.props.match
        const { id } = params
        const {
            nextUp,
            chipContainer,
            chip
        } = this.props.classes

        return (
            <App>
                <Box mt={8}>
                    <Container>
                        <Grid
                            spacing={4}
                            container
                        >
                            <Grid
                                xs={12}
                                sm={8}
                                item
                            >
                                {filePath &&
                                    <Player
                                        id={id}
                                        name={name}
                                        videoSrc={`${process.env.REACT_APP_API_URL}/${filePath}?id=${id}&access_token=${token}`}
                                        views={views * 31}
                                        onEnded={this.goToNextVideo}
                                    />
                                }
                                {!!tags.length &&
                                    <div>
                                        <Box my={2}>
                                            <Divider />
                                        </Box>
                                        <div className={chipContainer}>
                                            {tags.map((tag, index) => (
                                                <Chip
                                                    component={Link}
                                                    key={index}
                                                    className={chip}
                                                    label={tag}
                                                    to={`/videos?search=${tag}`}
                                                    clickable
                                                />
                                            ))}
                                        </div>
                                    </div>
                                }
                            </Grid>
                            <Grid
                                xs={12}
                                sm={4}
                                item
                            >
                                <Box mb={3}>
                                    <div className={nextUp}>
                                        Дараа нь
                                    </div>
                                </Box>
                                {suggests.map(({ _id, name, coverSrc, views }, index) =>
                                    <div key={_id}>
                                        <Box my={index !== 0 ? 1 : 0}>
                                            <CardVideoVariant
                                                id={_id}
                                                name={name}
                                                imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                                views={views * 31}
                                            />
                                        </Box>
                                        {!index &&
                                            <Box my={2}>
                                                <Divider />
                                            </Box>
                                        }
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </App>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token || ''
})

export default connect(mapStateToProps)(withStyles(styles)(Video))
