import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import { Redirect } from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core'
import {
    Check,
    CurrencyMnt
} from 'mdi-material-ui'
import { Authenticate } from './../layouts'
import {
    BaseDatePicker,
    FormatNumber,
    Section
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    card: {
        boxShadow: `
            rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
            rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
            rgba(0, 0, 0, 0.12) 0px 3px 3px -2px
        `
    }
})

class Affiliate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            disabled: false,
            from: null,
            to: null,
            commission: 0,
            total: 0
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        })
    }

    fetchData = async () => {
        try {
            const {
                from,
                to
            } = this.state

            const {
                commission,
                total
            } = await axios.get('/users/commission', {
                progress: true,
                params: {
                    from: from ? from.format('YYYY-MM-DD') : undefined,
                    to: to ? to.format('YYYY-MM-DD') : undefined
                }
            })

            this.setState({
                commission,
                total
            })
        } catch (err) {
            //
        }
    }

    render() {
        const {
            disabled,
            from,
            to,
            commission,
            total
        } = this.state
        const { user } = this.props
        const { card } = this.props.classes

        if (!user.aid) {
            return <Redirect to="/" />
        }

        return (
            <Authenticate>
                <Section title="Түншлэл">
                    <Grid
                        justify="center"
                        container
                    >
                        <Grid
                            xs={12}
                            md={8}
                            item
                        >
                            <Box mb={3}>
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <BaseDatePicker
                                            label="Эхлэх"
                                            value={from}
                                            onChange={this.handleDateChange('from')}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <BaseDatePicker
                                            label="Дуусах"
                                            value={to}
                                            onChange={this.handleDateChange('to')}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Button
                                            color="primary"
                                            onClick={this.fetchData}
                                            disabled={disabled}
                                            style={{
                                                margin: '10px 0'
                                            }}
                                        >
                                            Хайх
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Card className={card}>
                                <CardContent>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CurrencyMnt />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Нийт борлуулалтын дүн"
                                                secondary={
                                                    <FormatNumber
                                                        value={total}
                                                        suffix=" MNT"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <ListItemIcon>
                                                <Check style={{ color: '#4caf50' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Таны орлого"
                                                secondary={
                                                    <FormatNumber
                                                        value={commission}
                                                        suffix=" MNT"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Section>
            </Authenticate>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(Affiliate))
