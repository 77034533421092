import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Avatar,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core'
import { removeAuth } from './../redux/auth/actions'

const styles = theme => ({
    avatar: {
        //
    }
})

class UserMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            anchor: null
        }
    }

    openMenu = e => {
        this.setState({
            anchor: e.currentTarget
        })
    }

    closeMenu = () => {
        this.setState({
            anchor: null
        })
    }

    logout = () => {
        this.props.removeAuth()
        this.props.history.push('/login')
    }

    render() {
        const { anchor } = this.state
        const {
            classes,
            user
        } = this.props
        const { avatar } = classes

        return (
            <div>
                <IconButton
                    size="small"
                    onClick={this.openMenu}
                >
                    <Avatar
                        src={user.avatar ? `${process.env.REACT_APP_API_URL}/${user.avatar}` : `/static/images/user.png`}
                        className={avatar}
                    />
                </IconButton>
                <Menu
                    open={Boolean(anchor)}
                    onClose={this.closeMenu}
                    anchorEl={anchor}
                >
                    <MenuItem
                        component={Link}
                        to="/favorites"
                    >
                        Миний сан
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to="/profile"
                    >
                        Миний профайл
                    </MenuItem>
                    {!!user.aid && (
                        <MenuItem
                            component={Link}
                            to="/affiliate"
                        >
                            Түншлэл
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={this.logout}
                        button
                    >
                        Гарах
                    </MenuItem>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

const mapDispatchToProps = {
    removeAuth
}

const component = withStyles(styles)(withRouter(UserMenu))

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(component)
