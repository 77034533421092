import React, { Component } from 'react'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    Container,
    Typography
} from '@material-ui/core'

const styles = theme => ({

})

class Golomt extends Component {
    render() {
        const success = queryString.parse(this.props.location.search).status_code === '000'
        return (
            <div>
                <Container>
                    <Box
                        my={4}
                        textAlign="center"
                    >
                        <img
                            height="180"
                            src={
                                success
                                    ? '/static/images/active.png'
                                    : '/static/images/inactive.png'
                            }
                            alt="марчаахай"
                        />
                        <Box my={4}>
                            <Typography
                                variant="subtitle1"
                                component="div"
                            >
                                {
                                    success
                                        ? 'Таны багцын эрх амжилттай идэвхжлээ'
                                        : 'Таны төлбөр төлөлт амжилтгүй боллоо'
                                }
                            </Typography>
                        </Box>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/"
                            >
                                Нүүр хуудас руу буцах
                            </Button>
                        </div>
                    </Box>
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)(Golomt)
