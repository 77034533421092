import React, { Component } from 'react'
import queryString from 'query-string'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    TablePagination,
    Grid,
    withWidth
} from '@material-ui/core'
import { App } from './../layouts'
import {
    Section,
    CardVideo,
    Filters
} from './../components'
import axios from './../plugins/axios'

const rowsPerPage = 16

const styles = theme => ({
    //
})

class Videos extends Component {
    constructor(props) {
        super(props)

        const {
            categories,
            search
        } = queryString.parse(props.location.search, {
            arrayFormat: 'bracket'
        })

        this.state = {
            search: search || '',
            categories: Array.isArray(categories) ? categories : [],
            videos: [],
            currentPage: 0,
            total: 0
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (page = 1, callback = null) => {
        try {
            const {
                categories,
                search
            } = this.state

            const {
                total,
                currentPage,
                data
            } = await axios.get('videos', {
                progress: true,
                params: {
                    sortBy: 'isFree',
                    sortOrder: 'desc',
                    rowsPerPage,
                    page,
                    categories,
                    search
                }
            })

            this.setState({
                videos: data,
                currentPage: currentPage - 1,
                total
            }, () => {
                callback && callback()
            })
        } catch (err) {
            //
        }
    }

    onChangeCategories = categories => {
        this.setState({
            categories
        }, () => {
            this.fetchData()
        })
    }

    onChangeSearch = search => {
        this.setState({
            search
        }, () => {
            this.fetchData()
        })
    }

    paginate = async (e, page) => {
        this.fetchData(page + 1, () => window.scrollTo(0, 0))
    }

    render() {
        const { width } = this.props
        const {
            search,
            categories,
            videos,
            currentPage,
            total
        } = this.state

        return (
            <App>
                <Section title="Бүтээл">
                    <Box mb={4}>
                        <Filters
                            search={search}
                            categories={categories}
                            onChangeSearch={this.onChangeSearch}
                            onChangeCategories={this.onChangeCategories}
                        />
                    </Box>
                    <Grid
                        spacing={width === 'xs' ? 2 : 4}
                        container
                    >
                        {videos.map(({ _id, name, coverSrc, isFree }) =>
                            <Grid
                                key={_id}
                                xs={6}
                                sm={4}
                                lg={3}
                                item
                            >
                                <CardVideo
                                    id={_id}
                                    name={name}
                                    imageSrc={`${process.env.REACT_APP_API_URL}/${coverSrc}`}
                                    isFree={isFree}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Box mt={4}>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[]}
                            rowsPerPage={rowsPerPage}
                            count={total}
                            page={currentPage}
                            onChangePage={this.paginate}
                        />
                    </Box>
                </Section>
            </App>
        )
    }
}

export default withStyles(styles)(withWidth()(Videos))
