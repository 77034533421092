import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Box,
    Grid,
    Hidden,
    Typography
} from '@material-ui/core'

const styles = theme => ({
    //
})

class AboutIntro extends Component {
    render() {
        return (
            <Grid
                spacing={4}
                alignItems="center"
                container
            >
                <Grid
                    xs={12}
                    md={7}
                    lg={6}
                    item
                >
                    <Typography
                        align="center"
                        variant="body1"
                    >
                        "Марчаахай" ХХК нь дуу шүлэг, үлгэр, богино хэмжээний зохиомжуудаар дамжуулан 2-8 насны хүүхдүүдэд танин мэдэхүйн мэдлэг олгож, зөв хүн болж төлөвшихөд нь туслах зорилготойгоор байгуулагдан үйл ажиллагаа явуулж байна. Манай компани нь Монгол улсад анх удаагаа хүүхэлдэйн анимэйшн төрлөөр хүүхдэд зориулсан уран бүтээл хийж байгаа бөгөөд Монголын анхны хүүхдийн веб сайт, гар утасны аппликейшн бүхий контент худалдааны цогц системийг хүүхэд багачууддаа хүргээд байна.
                    </Typography>
                </Grid>
                <Hidden smDown>
                    <Grid
                        md={5}
                        lg={6}
                        item
                    >
                        <Box textAlign="center">
                            <img
                                width="380"
                                src="/static/images/app.png"
                                alt="marchaakhai"
                            />
                        </Box>
                    </Grid>
                </Hidden>
            </Grid>
        )
    }
}

export default withStyles(styles)(AboutIntro)
