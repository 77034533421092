import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
    typography: {
        fontSize: 18,
        fontFamily: 'Inform'
    },
    palette: {
        primary: {
            main: '#ff3747'
        },
        background: {
            default: '#fcfff2'
        }
    }
})
